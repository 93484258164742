import React, {useState, useEffect} from 'react';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, Box} from '@mui/material';

import Sidebar from './SideBar';
import PostTags from './PostTags'

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

 const getMUi = (block) => {
  
  if(block.type==='paragraph'){
    if(block.data.text.length===0){
      return <br />
    } else {
      return  <Typography   
                variant='h6' 
                key={block.id} 
                sx={{ mt: 1, mb: 1 }} 
                dangerouslySetInnerHTML={{ __html: block.data.text.replace(/href/g, "target='_blank' href") }} 
              />
    }
  }

  if(block.type==='embed'){
    const {height, width, embed} = block.data
    const em = `<iframe width=${width} height=${height} style="border:none; border-radius: 10px;" src=${embed}></iframe>`
    return <div style={{marginTop: 20, marginBottom: 20}} dangerouslySetInnerHTML={{ __html: em}} />
  }

  if(block.type==='raw'){
    return <div style={{marginTop: 20, marginBottom: 20}} dangerouslySetInnerHTML={{ __html: block.data.html}} />
  }


  if(block.type==='quote'){
    const txt = `<blockquote>${block.data.text}</blockquote>`
    const caption = block.data.caption
    return  <Box key={block.id}  
                sx={{ 
                  mt: 1, 
                  mb: 1, 
                  backgroundColor: '#f9f9f9', 
                  borderLeft: '10px solid #ccc', 
                  p:1,
                }}
            >
                <Typography 
                  variant='h5' 
                  color='darkgrey' 
                  fontStyle='italic'  
                  dangerouslySetInnerHTML={{ __html: txt }} 
                />
                {caption.length>0 &&
                  <Typography 
                    variant='h6' 
                    color='darkgrey' 
                    align='right'
                    sx={{m:3}}
                  >
                   {caption}
                  </Typography>
                }
            </Box> 
  }
 // <Typography component='div'>Normal text <Box fontWeight='fontWeightMedium' display='inline'>medium font weight text</Box> and some more normal text</Typography>

  if(block.type==='header'){
    const variant = `h${block.data.level}`
    return <Typography sx={{mt:1, mb:1}} variant={variant} >{block.data.text}</Typography>
  }

  if(block.type==='simpleImage'){
    return <img style={{borderRadius: 5, marginTop:10, marginBottom:10}} width="100%" height="auto" src={block.data.url} alt={block.data.caption} />
  }

  if(block.type==='delimiter'){
    return <Divider sx={{mt:2, mb:2}} />
  }

  if(block.type==='list'){
    const data = block.data
    const list = data.items.map((l,i) => <li key={i}><Typography variant='h6' >{l}</Typography></li>)
    if(data.style==='unordered'){
      return <ul>{list}</ul>
    } else {
      return <ol>{list}</ol>
    }
  }

  if(block.type==='table'){
    const {content, withHeadings} = block.data
    if(withHeadings){
      const header = content[0]
      const rows = content.slice(1)
      return (
        <TableContainer component={Paper}>
          <Table >
            <TableHead>
              <TableRow >
                {header.map((h, i) =>  
                  <TableCell  key={i}>
                    {h}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                 {row.map((h, i) =>  
                  <TableCell   key={i}>{h}</TableCell>
                )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} >
          <TableBody>
            {content.map((row, i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {row.map((h, i) =>  
                  <TableCell key={i}>{h}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      );
      }
    }
}

export default function PostContent({
    date,
    title,
    authorName,
    summary,
    post_image,
    siteDescription,
    siteTwitter,
    siteFacebook,
    adminEmail,
    recentPosts,
    authorId,
    post_tags,
    blocks,
}) {
  const size = useWindowSize() 
  const imgheight = Math.floor(size.width*.2) ?? 100

return  <Grid container spacing={5}  >
              <Grid
                item
                xs={12}
                lg={8}
              >
              <Grid container spacing={2} >
                <Grid item >
                  <Typography fontWeight='' variant="h4" >
                    {title}
                  </Typography>
                </Grid>
                  <Grid item>
                    <Grid 
                      container 
                      spacing={1} 
                      justifyContent='space-between' 
                      alignItems='center' 
                    >
                      <Grid item >
                        <Typography variant="body1" color='textSecondary'>
                          {date} by <Link  href={`/author/${authorId}/page/1`} >{authorName}</Link> 
                        </Typography>
                      </Grid>
                      <Grid item >
                        <PostTags post_tags={post_tags}  />
                    </Grid>
                  </Grid>
                  <Grid item  xs={12}>
                    <Typography 
                      variant="h6" 
                      color='textSecondary' 
                      sx={{mt:2, mb:2}}
                    >
                      {summary}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      sx={{
                        maxHeight: 200,
                        height: imgheight,
                        position: 'relative',
                        backgroundColor: 'grey.800',
                        color: '#fff',
                        mb: 2,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${post_image})`,
                      }}
                    >
                    </Paper>
          
                 </Grid>
                 <Grid item >
                    {blocks && blocks.blocks.map(b => 
                      <div key={b.id}>
                        {getMUi(b)}
                       </div>
                    )}
                  </Grid>
                  <Grid item >
                    <Box sx={{pt:10}} />
                  </Grid>
                </Grid>
                </Grid>
              </Grid>
              <Sidebar 
                siteDescription={siteDescription}
                siteTwitter={siteTwitter}
                siteFacebook={siteFacebook}
                adminEmail={adminEmail}
                recentPosts={recentPosts}
              />
           </Grid>
}