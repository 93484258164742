import React, { useState } from "react"

// DATA

// clients
import { supabase } from "../supabase"

import { useQuery } from "react-query"

// queries
import { 
  getMember,
} from '../queries/memberQueries'

import { 
  postQuery,
} from "../queries/blogQueries"


// UI

// custom components
import SearchPage from '../components/Pages/SearchPage'

export default function AddPost(){

    // DATA

    // get
    const { 
      isLoading: userLoading, 
      data: user,
     } = useQuery('member', () => getMember())

    const authorName = !userLoading && user ? user.name : ''

    // state
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)
    const [ url, setSearchLink ] = useState('')
    const [ featured, setFeatured ] = useState(false)
    const [ title, setTitle ] = useState('')
    const [ text, setText ] = useState('')
    const [ searchResults, setSearchResults ] = useState([])
    const [ loading, setLoading ] = useState(false)

    const handleLink = event => setSearchLink(event.target.value)
    const handleText = event => setText(event.target.value)
    const handleTitle = event => setTitle(event.target.value)
    const handleClose = () => setSnack(false)
    const handleFeatured = () => setFeatured(!featured)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    // functions
    const searchPosts = async () => {
      setLoading(true)

      const { data, error } = await supabase
      .from('blogs')
      .select(postQuery)
      .textSearch('article_text', text, { 
        type: 'websearch',
        config: 'english'
      })
      .order('published_date', {ascending: false})
      .limit(10)

      if(error) {
          showSnack('error','Search error!')
      }
    
      if(!data) {
        showSnack('error','Search error!')
      }
      setLoading(false)
      setSearchResults(data)
    }

    const searchTitle = async () => {
      setLoading(true)
    
      const { data, error } = await supabase
      .from('blogs')
      .select(postQuery)
      .textSearch('title', title, { 
        type: 'websearch',
        config: 'english'
      })

      .order('published_date', {ascending: false})
      .limit(10)

    if(error) {
        showSnack('error','Search error!')
    }

    if(!data) {
      showSnack('error','Search error!')
    }
      setLoading(false)
      setSearchResults(data)
    }

    return <SearchPage
            url={url}
            title={title}
            text={text}
            loading={loading}
            handleLink={handleLink}
            handleText={handleText}
            handleTitle={handleTitle}
            searchPosts={searchPosts}
            searchTitle={searchTitle}
            snack={snack}
            msgType={msgType}
            alertMsg={alertMsg}
            handleClose={handleClose}
            authorName={authorName}
            searchResults={searchResults}
            handleFeatured={handleFeatured}
          />
}