import React from "react"

import Grid from '@mui/material/Grid'
import { Button, Typography } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton'

import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import PublishIcon from '@mui/icons-material/Publish'
import PreviewIcon from '@mui/icons-material/Preview'

import SnackAlert from '../Dialogs/SnackAlert'
import BasicLinkIconButton from '../Buttons/BasicLinkIconButton'
import PublishedPosts from '../Cards/PublishedPosts'
import UnPublishedPosts from '../Cards/UnPublishedPosts'


export default function PostListPage({ 
  snack,
  msgType,
  publishPost,
  publisher,
  alertMsg,
  loading,
  handleClose,
 }){

    return(

      <main >
            <Grid container  
                  alignItems="center" 
                  spacing={1} 
                  justifyContent='space-between'
            >
               <Grid item > 
               <Grid container spacing={3}>
                  <Grid item>
                    <Typography variant="h5"  color='primary'>
                      Posts 
                    </Typography>
                  </Grid>
                  {publisher &&
                    <Grid item > 
                      <LoadingButton
                        size='small'
                        loading={loading}
                        onClick={publishPost} 
                        color='success'
                        variant="contained"
                        startIcon={<PublishIcon />}
                      >
                        Publish
                      </LoadingButton>
                    </Grid>
                    }
                     <Grid item > 
                      <Button 
                        size='small'
                        color='warning'
                        variant="contained"
                        href='/blog_preview' 
                        target='_blank'
                        startIcon={<PreviewIcon />}
                      >
                        Preview
                      </Button>
                   </Grid>
                  </Grid>
               </Grid>
               <Grid item > 
                <Grid container  
                  alignItems="center" 
                  spacing={1} 
                >
                  <Grid item > 
                    <BasicLinkIconButton
                        size="small"
                        title="Add"
                        to='/user/add_post'
                        icon={<AddIcon />}
                    />
                  </Grid>
                  <Grid item > 
                    <BasicLinkIconButton
                        size="small"
                        title="Search"
                        to='/user/search_posts'
                        icon={<SearchIcon />}
                    />
                  </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12}> 
                <Grid container  
                    alignItems="start" 
                    spacing={2} 
                    justifyContent='space-between'
                >
                  <Grid item xs={12} md={6}>
                  <UnPublishedPosts  />
                    {/* <EditPostList 
                      posts={posts} 
                      published={false} 
                      title='Unpublished'
                    /> */}
                  </Grid>
                  <Grid item  xs={12} md={6}>
                    <PublishedPosts  />
                    {/* <EditPostList 
                      posts={posts} 
                      published={true} 
                      title='Published'
                    /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          /> 
        </main>
    )
}