import React from 'react'
import {langSwitch} from '../../utils'

import Avatar from '@mui/material/Avatar';

const pixs = 8

const sizes = {
  xsmall: {
    width: pixs * 2,
    height: pixs * 2,
  },
  small: {
    width: pixs * 3,
    height: pixs * 3,
  },
  medium: {
      width: pixs * 5,
      height: pixs * 5,
  },
  large: {
      width: pixs * 7,
      height:pixs * 7,
    },
  xlarge: {
      width: pixs * 9,
      height: pixs * 9,
  },
}

const FlagRound = ({
  flagSize,
  lang
}) => <Avatar 
        alt={langSwitch(lang)} 
        src={`/${lang}.svg`} 
        style={sizes[flagSize]}
      />

export default FlagRound