import React from 'react'
import { useParams } from "react-router-dom"

import { 
  useQuery,
} from "react-query"

import {
  getLangRssFeeds,
} from '../queries/statsQueries'

import LangRssPage from '../components/Pages/LangRssPage'

export default function LangRss(){

  const title = ''

  const {
    lang, 
  } = useParams()


  const { 
    isLoading, 
    data,
  } = useQuery('feeds', () => getLangRssFeeds(lang))

  const rssFeeds = !isLoading ? data : []
  return <LangRssPage
          title={title}
          lang={lang}
          rssFeeds={rssFeeds}
          loading={isLoading}
        />
  }
