import { 
    useQuery,
 } from "react-query"

import {queryClient} from '../index'

import {supabase} from "../supabase"

import {createSite} from './siteQueries'

const table = 'profile'

export const getAuthUser = async () => {
  const response = await supabase.auth.getSession()
  if(response.data.session!==null){
      return response.data.session.user
  }
  return null
}

export const createMember =  async ({
    email,
    id,
}) => {
    const count = await getAdminCount()

    if(count===0){
      await createSite({admin_email: email})
    }
    const role = count>0 ? 'new' : 'admin'

    const { data, error } = await supabase
        .from(table)
        .insert({
            email,
            name: '',
            role,
            auth_id:id,
        })

    if(error)  throw new Error(error.message)

    return data
}

export const getAdminCount = async () => {
    const { data, error } = await supabase
      .from(table)
      .select('id')
      .eq('role', 'admin')
  
    if(error)  throw new Error(error.message)
  
    return data.length
  }

export const updateMember = async update => {
    const user = await getAuthUser()
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', user.id)

    if(error) throw new Error(error.message)

    return data
}

export const updateRole = async update => {
  const { data, error } = await supabase
      .from(table)
      .update({role: update.role})
      .eq('id', update.id)

  if(error) {
      throw new Error(error.message)
  }

  return data
}

export const getMember = async () => {
    const { data, error } = await supabase
      .from(table)
      .select()
      .single()

    if(error)  throw new Error(error.message)
  
    if(!data)  throw new Error("Member not found")
  
    return data
  }

export const getNewMembers = async () => {
  const { data, error } = await supabase
    .from('profile')
    .select('*')
    .eq('role', 'new')

    if(error)  throw new Error(error.message)
  
    if(!data)  throw new Error("Member not found")

    return data
}

export const getExistingMembers = async () => {
  const { data, error } = await supabase
    .from('profile')
    .select('*')
    .or('role.eq.admin, role.eq.writer, role.eq.editor')

    if(error)  throw new Error(error.message)
  
    if(!data)  throw new Error("Member not found")

    return data
}

export const useMember = () => useQuery('member', () => getMember())

export const prefetchMember = async () => await queryClient.prefetchQuery('member', getMember())
