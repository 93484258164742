import React from 'react'

// mui
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

// custom components
import SocialList from './SocialList'
import AboutCard from './AboutCard'
import Contact from './Contact'
import RecentArticles from './RecentArticles'
import Banner250300 from './Ads/Banner250300'
import AffiliateLinks from './AffiliateLinks'

import {
    Banner2503001, 
    Banner2503002, 
    Banner2503003
} from './Ads/Banner250300'

const Sidebar = ({
    siteDescription,
    siteTwitter,
    siteFacebook,
    adminEmail,
    recentPosts,
    taglang,
}) => {
    return  <Grid item xs={12} md={4}>
                <AboutCard 
                    siteDescription={siteDescription} 
                />
                <Grid>
                    <Divider sx={{mt:3}} />
                </Grid>
                <RecentArticles 
                    recentPosts={recentPosts}
                    taglang={taglang}
                />
                <Grid>
                    <Divider sx={{mt:3, mb: 3}} />
                </Grid>
                <Grid>
                   <AffiliateLinks />
                </Grid>

                 <Grid>
                    <div  style={{height:20}} />
                    {/* <Banner250300 /> */}
                </Grid>
                  <SocialList  
                    siteTwitter={siteTwitter}
                    siteFacebook={siteFacebook} 
                />
                <Contact 
                    adminEmail={adminEmail} 
                />
                <Grid>
                    <div  style={{height:20}} />
                    {/* <Banner2503001 /> */}
                </Grid>
                <Grid>
                    <div  style={{height:20}} />
                    {/* <Banner2503002 /> */}
                </Grid>
                <Grid>
                    <div  style={{height:20}} />
                    {/* <Banner2503003 /> */}
                </Grid>
            </Grid>
}

export const SidebarPlain = ({
    siteDescription,
    siteTwitter,
    siteFacebook,
    adminEmail,
}) => {

    return  <Grid item xs={12} md={4}>
                <AboutCard 
                    siteDescription={siteDescription} 
                />
                <Grid>
                    <Divider sx={{mt:3, mb: 3}} />
                </Grid>
                <Grid>
                   <AffiliateLinks />
                </Grid>
                 <Grid>
                    <div  style={{height:20}} />
                    {/* <Banner250300 /> */}
                </Grid>
                  <SocialList  
                    siteTwitter={siteTwitter}
                    siteFacebook={siteFacebook} 
                />
                <Contact 
                    adminEmail={adminEmail} 
                />
                <Grid>
                    <div  style={{height:20}} />
                    {/* <Banner2503001 /> */}
                </Grid>
                <Grid>
                    <div  style={{height:20}} />
                    {/* <Banner2503002 /> */}
                </Grid>
                <Grid>
                    <div  style={{height:20}} />
                    {/* <Banner2503003 /> */}
                </Grid>
            </Grid>
}

export default Sidebar