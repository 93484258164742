import {supabase} from "../supabase"

const table = 'site'

export const getSite = async () => {
    const { data, error } = await supabase
        .from(table)
        .select(`*`)

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Cluster not found")

    return data[0]
}

export const createSite =  async ({
    admin_email
}) => {
    const { data, error } = await supabase
    .from(table)
    .insert({
        admin_email,
    })

    if(error) throw new Error(error.message)

    return data
}

export const updateSite = async update => {
    const { data, error } = await supabase
        .from(table)
        .upsert(update)

    if(error) throw new Error(error.message)

    return data
}


