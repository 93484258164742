import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import List from '@editorjs/list'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Delimiter from '@editorjs/delimiter'
import SimpleImage from '@editorjs/simple-image'
import RawTool from '@editorjs/raw';
import Paragraph from '@editorjs/paragraph'
 // <iframe width="383" height="680" src="https://www.youtube.com/embed/nSA1EYGfG7I" title="Hit by 12 million" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
export const EDITOR_JS_TOOLS1 = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  embed: Embed,
  table: Table,
  list: List,
  code: Code,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      preserveBlank: true,
    },
  },
  // image: SimpleImage,
  linkTool: LinkTool,
  header: Header,
  quote: Quote,
  delimiter: Delimiter,
  simpleImage: SimpleImage,
  raw: RawTool,
}


// <iframe width="383" height="680" src="https://www.youtube.com/embed/nSA1EYGfG7I" title="Hit by 12 million" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  embed: {
    class: Embed,
    config: {
      services: {
        youtube: true,
        instagram: true,
        facebook: true, 
        twitter: true,
        youtubeshorts: {
          regex: /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|shorts\/))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/,
          embedUrl: 'https://www.youtube.com/embed/<%= remote_id %>',
          html: '<iframe width="383" height="680" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
          height: 680,
          width: 383,
        }
      }
    },
  },
  table: Table,
  list: List,
  code: Code,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      preserveBlank: true,
    },
  },
  // image: SimpleImage,
  linkTool: LinkTool,
  header: Header,
  quote: Quote,
  delimiter: Delimiter,
  simpleImage: SimpleImage,
  raw: RawTool,
}