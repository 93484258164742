import React, { useState } from 'react'

// DATA

// clients
import {supabase} from '../supabase'

// queries
import { 
  getMember,
} from '../queries/memberQueries'

// UI

// components
import SignInPage from '../components/Pages/SignInPage'

export default function SignIn({history}){

  // state
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ alert, setAlert ] = useState('')
  const [ loading, setLoading] = useState(false)

  const handleEmail = event => setEmail(event.target.value)
  const handlePassword = event => setPassword(event.target.value)

  const inputs = [
    {
      name:'email',
      label: 'Email',
      handleFunc: handleEmail,
      value: email
    },
    {
      name:'password',
      label: 'Password',
      handleFunc: handlePassword,
      value: password
    }
  ]

  // functions
  const checkUser = async () => {
    try{
      const user = await getMember()
      if(!user){ 
        setAlert('You do not have access.')
        resetInput()
        return
      }
      if(user.role==='deactivated'){ 
        setAlert('Account is no longer activated.')
        resetInput()
        return
      }
      if(!user || user.role==='new'){ 
        setAlert('Your account needs to be assigned a role by the site administator')
        resetInput()
        return
      }
      return history.push('/user/posts')
     } catch(error){
       console.log(error)
       resetInput()
       setAlert('You do not have access.')
    }
  }

  const resetInput = () => {
    setEmail('')
    setPassword('')
  }

  const loginPassword = async () => {
    try {
      setLoading(true)
      const { error } = await supabase.auth.signInWithPassword({ email, password })
     if (error)  {
        setAlert(error.message)
     }
     checkUser()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return <SignInPage
          inputs={inputs} 
          loginPassword={loginPassword} 
          alert={alert}
          loading={loading}
        />
}