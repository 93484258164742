import React, { useState, useEffect, useRef, useCallback } from "react"
import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../tools'
// packages
import { useParams } from "react-router-dom"

// helpers
import { slugify } from '../utils'

// DATA

// clients
import { supabase } from "../supabase"
import { queryClient } from ".."

import { 
  useMutation, 
  useQuery,
} from "react-query"

// queries

import {
  getTags, 
  getPost, 
  createTag,
  updatePost,
} from '../queries/blogQueries'

import {
  createPostTags,
  deletePostTag,
} from '../queries/tagsQueries'

import {
  getAuthors
} from '../queries/authorQueries'

import {getMember} from '../queries/memberQueries'

// UI

// mui
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Tooltip from '@mui/material/Tooltip'
import ImageIcon from '@mui/icons-material/Image'
import AddIcon from '@mui/icons-material/Add'
import Link from "@mui/material/Link"
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IconButton, Divider } from "@mui/material"

import ListIcon from '@mui/icons-material/List';
import LinkIcon from '@mui/icons-material/Link'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon  from '@mui/icons-material/Instagram'
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack'
import PreviewIcon from '@mui/icons-material/Preview'
import PublishIcon from '@mui/icons-material/Publish'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import EditIcon from '@mui/icons-material/Edit'
import ExpandIcon from '@mui/icons-material/Expand'


import {TikTokIcon} from '../components/ImageElements/BlogIcons'

// custom components
import TagDropDown from '../components/Inputs/TagDropDown'
import LangDropDown from "../components/Inputs/LangDropDown"
import BlogInput from '../components/Inputs/BlogInput'
import AddTagDialog from '../components/Dialogs/AddTagDialog'
import PostTag from '../components/Inputs/PostTag'
import AuthorDropDown from '../components/Inputs/AuthorDropDown'

import BasicIconButton from '../components/Buttons/BasicIconButton'

import LoadingIconButton from '../components/Buttons/LoadingIconButton'

import SnackAlert from '../components/Dialogs/SnackAlert'
import LoadingIconButton1 from '../components/Buttons/LoadingIconButton1'
import LoadingIconButton2 from '../components/Buttons/LoadingIconButton2'


// custom components
// import { CircularProgress } from "@mui/material";

const baseUrl = 'https://langalearn.com/post'
const langs = ['en', 'es', 'de', 'it', 'fr', 'pt']

const imageSites = [
  {
      title: 'Unsplash',
      url: 'https://unsplash.com'
  },
  {
      title: 'Pexels',
      url: 'https://www.pexels.com'
  },
  {
      title: 'Pixabay',
      url: 'https://pixabay.com/'
  },
]

export default function UpdatePost(){

    const ReactEditorJS = createReactEditorJS()

    const editorCore = useRef(null);

    // DATA
    
    // fetching

    const { 
      id,
    } = useParams()

    const { 
      isLoading: tagLoading, 
      data: tags1,
     } = useQuery('tags', () => getTags())

    const { 
      isLoading: memberLoading, 
      data: user,
     } = useQuery('member', () => getMember())

    const { 
      isLoading, 
      data: post,
    } = useQuery('post', () => getPost({id}))

    const { 
      isAuthorLoading, 
      data: authors,
    } = useQuery('active_authors', () => getAuthors(true))
    

    // const authorName = !memberLoading && user ? user.name : ''
    const author_id = !memberLoading && user ? user.id : ''
    const authors1 = !isAuthorLoading && authors ?  authors : []
    const video = !isLoading && post ? post.video : false
    const tweet = !isLoading && post ? post.tweet : false
    const facebook = !isLoading && post ? post.facebook : false
    const instagram = !isLoading && post ? post.instagram : false
    const youtube = !isLoading && post ? post.youtube : false
    const tiktok = !isLoading && post ? post.tiktok : false
    const slug = !isLoading && post ? post.slug  : ''
    const tags = !tagLoading && tags1 ? tags1 : []
    const firstTag = tags.length>0 ? tags[0].id : 1

    // changes
    const updatePostMutation = useMutation(() => updatePost({
      title,
      slug: slugify(title),
      lang, 
      summary,
      blocks,
      post_image,
      tag_id: tag,
      featured,
      author: postAuthor,
      id,
    }), {
      onSuccess: (data) => {
        queryClient.invalidateQueries('posts')
        queryClient.invalidateQueries('post')
        showSnack('success', 'Success! Post updated!')
      },
      onError: (error) => {
        showSnack('error', 'Error updating post! ' + error)
      },
      onSettled: ()=>{
        setLoading(false)
      }
    })

    const addTagMutation = useMutation(() => createTag({
      tabName: addtag,
      author_id
    }), {
      onSuccess: () => {
        queryClient.invalidateQueries('tags')
        setAddTag('')
        showSnack('success', 'Success! New tag added!')
      },
      onError: (error) => {
        showSnack('error', 'Error adding new tag!')
      },
      onSettled: (data)=>{
        setLoading(false)
      }
    })

    // state management
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)
    const [ title, setTitle ] = useState('')
    const [ summary, setSummary ] = useState('')
    const [ featured, setFeatured ] = useState(false)
    const [ postAuthor, setPostAuthor ] = useState(2)
    const [ lang, setLang ] = useState('en')
    const [ post_image, setPost_image ] = useState('')
    const [ tag, setTag ] = useState(firstTag)
    const [ addtag, setAddTag ] = useState('')
    const [ tagColor, setTagColor ] = useState('')
    const [ published, setPublished ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ openEditor, setOpenEditor] = useState(false)
    const [ tagOpen, setTagOpen ] = useState(false)
    const [ postTags, setPostTags ] = useState([])
    const [ blocks, setBlocks ] = useState()

    const errorText = false

    const handleLang = event => setLang(event.target.value)
    const handleTag = event => setTag(event.target.value)
    const handlePost_image = event => setPost_image(event.target.value)
    const handleSummary = event => setSummary(event.target.value)
    const handleTitle = event => setTitle(event.target.value)
    const handleaddTag = event => setAddTag(event.target.value)
    const handleTagColor = event => setTagColor(event.target.value)
    const handlePostAuthor = event => setPostAuthor(event.target.value)

    const handleEditorModal = () => setOpenEditor(!openEditor)
    const toggleTagOpen = event => setTagOpen(!tagOpen)
    const handleClose = () => setSnack(false)
    const handleFeatured = () => setFeatured(!featured)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    // functions
    
    const updatePost1 = async () => {
      if(title.length>0 && summary.length>0 && post_image.length>0 && blocks!==null && blocks.blocks.length>1){
        setLoading(true)
        updatePostMutation.mutate()
      } else {
        showSnack('error', 'Please add text in the required fields')
      }
    }

    const getSocialUpdate = (social) => {
      if(social==='facebook') return {'facebook': !facebook}
      if(social==='tweet') return {'tweet': !tweet}
      if(social==='youtube') return {'youtube': !youtube}
      if(social==='instagram') return {'instagram': !instagram}
      if(social==='tiktok') return {'tiktok': !tiktok}
    }

    const updateSocial = async (social) => {
      const update = getSocialUpdate(social)
      const { error } = await supabase
        .from('blogs')
        .update(update)
        .eq('id', id)
      if(error) {
        showSnack('error', `Error updating ${social} ` + error)
      }
      queryClient.invalidateQueries('posts')
      queryClient.invalidateQueries('post')
      showSnack('success', `Success! ${social}`)
    }

    const updateVideo = async () => {
      const { error } = await supabase
        .from('blogs')
        .update({video: !video})
        .eq('id', id)
      if(error) {
        showSnack('error', `Error updating video ` + error)
      }
      queryClient.invalidateQueries('posts')
      queryClient.invalidateQueries('post')
      showSnack('success', `Success!`)
    }

    const publishPost1 = async () => {
      setLoading(true)
      const { data, error } = await supabase
      .from('blogs')
      .update({published: !published})
      .eq('id', id)
      .select('published')
 
      if(error) {
        showSnack('error', 'Error updating post! ' + error)
        setLoading(false)
      }

      if(data){
        try {
          const post = data[0]
          setPublished(post.published)
          queryClient.invalidateQueries('posts')
          queryClient.invalidateQueries('post')
          const pub = post.published ? 'Published' : 'Unpublished'
          showSnack('success', `Success! Set to ${pub}`)
        } catch(error) {
          showSnack('error', 'Error updating post! ' + error)
        } finally {
          setLoading(false)
        }
      }
    }

    const addPostTag = async () => {
      if(!postTags.includes(tag)){
        setPostTags([...postTags, tag])
        const newPostTag = {post_id: id, tag_id: tag}
        await createPostTags(newPostTag)
      }
    }

    // const deleteTag = async tag_id => {
    //   const newtags = postTags.filter(p => p!==tag_id)
    //   setPostTags(newtags)
    //   await deletePostTag({tag_id, post_id: id})
    // }

    const newTag = async () => addTagMutation.mutate()

    const launchPreview = () => window.open(`/post/${id}`);

    const handleSave = useCallback(async () => {
      // retrieve data inserted
      const savedData = await editorCore.current.save();
      // save data
      setBlocks(savedData);
    }, [setBlocks]);

    const handleInitialize = useCallback((instance) => {
      // await instance._editorJS.isReady;
      instance._editorJS.isReady
        .then(() => {
          // set reference to editor
          editorCore.current = instance;
        })
    }, []);

    useEffect(()=>{
     
      // set fetched data
      if(!isLoading && post){
        const pTags = post.post_tags.map(t => t.tags.id)
        setTitle(post.title)  
        setSummary(post.summary)
        setLang(post.lang)
        setPost_image(post.post_image)
        setFeatured(post.featured)
        setPublished(post.published)
        if(post.author){
          setPostAuthor(post.author.id)
        } else {
          if(authors1.length>0){
            setPostAuthor(authors[0].id)
          }
        }
        setPostTags(pTags)
        if(post.blocks){
          setBlocks(post.blocks)
        } else {
          setBlocks([])
        }
       
      }

    },[post, isLoading, authors1])
    // if (isLoading || tagLoading || memberLoading) return <CircularProgress />
    const url = `${baseUrl}/${slug}`

    return (
      <main >
        <Grid container  
              alignItems="center" 
              spacing={2} 
              justifyContent='space-between'
        >   
          <Grid item xs={12}> 
            <Grid container  
                  alignItems="center" 
                  spacing={2} 
                  justifyContent='space-between'
            >
                 <Grid item xs={12} md={6} lg={8}> 
                  <FormControl sx={{ width: '100%'}}>
                    <BlogInput 
                      required={true}
                      errorText={errorText}
                      title={title}
                      handleTitle={handleTitle}
                      label="Title" 
                    />
                  </FormControl>  
                </Grid>
                <Grid item xs={12} md={3} lg={2}> 
                  {authors1.length>0 &&
                    <AuthorDropDown
                      authors={authors1}
                      postAuthor={postAuthor}
                      handlePostAuthor={handlePostAuthor}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={3} lg={2}> 
                  <LangDropDown
                    lang={lang}
                    langs={langs}
                    handleLang={handleLang}
                  />
                </Grid>
                <Grid item xs={12}> 
                  <BlogInput 
                    required={true}
                    errorText={errorText}
                    title={summary}
                    handleTitle={handleSummary}
                    label="Summary" 
                  />
                </Grid>  
                <Grid item xs={12}> 
                  <Grid container  
                      alignItems="center" 
                      spacing={2} 
                      justifyContent='space-between'
                  >
                    <Grid item >
                      <Grid container  
                        alignItems="center" 
                        spacing={2} 
                        justifyContent='space-between'
                    >
                      {tags.length>0 &&
                        <Grid item  >
                          <TagDropDown 
                            tag={tag}
                            tags={tags}
                            handleTag={handleTag}
                          />
                        </Grid>
                      }
                      <Grid item> 
                        <BasicIconButton 
                          size='small'
                          title='Add Tag'
                          actionFunc={addPostTag}
                          icon={<AddIcon />}  
                          />
                      </Grid>
                      <Grid item> 
                        <BasicIconButton 
                          size='small'
                          title='Tags'
                          actionFunc={toggleTagOpen}
                          icon={<ListIcon />}  
                          />
                      </Grid>
                      {postTags.length>0 && 
                        <Grid item > 
                          <Grid container spacing={2} alignItems='center'>
                            {postTags.map(p =>  
                              <PostTag 
                                key={p} 
                                tags={tags} 
                                tag_id={p} 
                                deletePostTag={deletePostTag} 
                              />
                            )}
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                  <Grid item >
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item>
                        <FormGroup>
                          <FormControlLabel control={<Switch checked={featured} onChange={handleFeatured}/> }  label="Featured" />
                        </FormGroup>      
                      </Grid>
                      <Grid item>
                        |
                      </Grid>
                      {published &&
                        <Grid item>
                          <Tooltip title="Published Url">
                            <Link href={url} target='_blank'>
                                <LinkIcon  />
                            </Link>
                          </Tooltip>
                        </Grid>
                      }
                       <Grid item>
                        <Tooltip title="Video Created">
                          <IconButton onClick={() => updateVideo()}>
                            <VideoCameraBackIcon color={video ? 'primary' : 'disabled'} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      {video &&
                        <Grid item>
                          <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                              <Tooltip title="Facebook">
                                <IconButton onClick={() => updateSocial('facebook')}>
                                  <FacebookIcon color={facebook ? 'primary' : 'disabled'} />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item> 
                              <Tooltip title="Twitter">
                                <IconButton onClick={() => updateSocial('tweet')}>
                                  <TwitterIcon color={tweet ? 'primary' : 'disabled'} />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Youtube">
                                <IconButton onClick={() => updateSocial('youtube')}>
                                  <YouTubeIcon color={youtube ? 'primary' : 'disabled'} />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Instagram">
                                <IconButton onClick={() => updateSocial('instagram')}>
                                  <InstagramIcon color={instagram ? 'primary' : 'disabled'} />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Tiktok">
                                <IconButton onClick={() => updateSocial('tiktok')}>
                                  <TikTokIcon color={tiktok ? '#6415ff' : 'lightgrey'} />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                  </Grid>
                  </Grid> 
                </Grid>
                </Grid> 
                <Grid item  xs={12}> 
                  <Grid container justifyContent='space-between' >
                    <Grid item md={8} xs={12} > 
                      <BlogInput 
                        required={true}
                        errorText={errorText}
                        title={post_image} 
                        handleTitle={handlePost_image}
                        label="Post Image" 
                      />
                    </Grid>

                    <Grid item >
                      {post_image.length>0 ?
                        <img src={post_image} alt='selected pick' height='80' style={{borderRadius: 5}}/>
                        :
                        <ImageIcon sx={{ fontSize: 36 }}  color='primary'/>
                      }
                    </Grid> 

                    <Grid item xs={12}>
                      <Grid container spacing={1}  >
                        {imageSites.map(s => 
                          <Grid item key={s.title}>
                            <Link href={s.url} target="_blank" color='#6415ff' variant='body2'  >
                              {s.title}
                            </Link>
                          </Grid>
                        )}
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
                <Grid item  xs={12}> 
                  <Divider />
                </Grid>
                <Grid item xs={12}> 
                  {blocks &&
                    <ReactEditorJS 
                      defaultValue={blocks} 
                      tools={EDITOR_JS_TOOLS} 
                      onChange={handleSave}
                      onInitialize={handleInitialize}
                    />
                  }
                </Grid>
                
              </Grid>
          </Grid>
          <Grid item xs={12} sm={3}  >
            {published ?
              <LoadingIconButton2
                size='small'
                onClick={publishPost1}
                title='Unpublish'
                loading={loading}
                icon={<UnpublishedIcon />}
              /> 
              :
              <LoadingIconButton1
                size='small'
                onClick={publishPost1}
                title='Publish'
                loading={loading}
                icon={<PublishIcon />}
              /> 
            }
          </Grid>
          <Grid item xs={12} sm={3}  >
            <LoadingIconButton
              size='small'
              onClick={updatePost1}
              title='Update'
              loading={loading}
              icon={<EditIcon />}
            /> 
          </Grid>
          <Grid item xs={12} sm={3}  >
            <BasicIconButton 
              fullWidth={true}
              size='small'
              title= 'Enlarge'
              actionFunc={handleEditorModal}
              icon={<ExpandIcon />}  
            />
          </Grid>
          <Grid item xs={12} sm={3}  >
            <BasicIconButton 
              fullWidth={true}
              size='small'
              title='Preview'
              actionFunc={launchPreview}
              icon={<PreviewIcon />}  
            />
          </Grid>
        </Grid>
        <AddTagDialog  
          handleTagColor={handleTagColor}
          tagColor={tagColor}
          handleaddTag={handleaddTag}
          addTag={newTag}
          errorText={errorText}
          newTag={newTag}
          tagOpen={tagOpen}
          toggleTagOpen={toggleTagOpen}
          author_id={author_id}
        />
        <SnackAlert 
          snack={snack}
          handleClose={handleClose}
          msgType={msgType}
          alertMsg={alertMsg}
        /> 
      </main>
    )
}