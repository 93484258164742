import * as React from 'react';

import {langSwitch} from '../../utils'

import Button from '@mui/material/Button';
import Link  from '@mui/material/Link';
import FlagRound from './FlagRound';

const PostTag = ({
  id, 
  tag_name, 
  tag_color,
}) => {
  return  <Link  href={`/tag/${id}/page/1`} >
            <Button  size='small'style={{color: tag_color}}  >
              {tag_name} 
            </Button>
          </Link> 
}

const LangPostTag = ({
  id, 
  tag_name, 
}) => {
  return   <Link href={`/tag/${id}/page/1`} >
              <Button size='small' sx={{color: '#6415ff'}}  >
                <FlagRound lang={tag_name} flagSize='xsmall' /> &nbsp; {langSwitch(tag_name)} 
              </Button>
          </Link> 
}

const PostTags = ({ post_tags }) => post_tags.map(t => t.tags.language==true ? <LangPostTag key={t.tags.id} {...t.tags} /> : <PostTag key={t.tags.id} {...t.tags} />)

export default PostTags