import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'

const LoginHelp = () => {
    return(
    <Grid container 
          justifyContent="space-between"
    >
        <Grid item>
            <Link 
                to="/forgotpassword"
                component={RouterLink} 
                variant="body2"  
                color="#6415ff"
            >
                Password
            </Link>
        </Grid>
        <Grid item>
            <Link 
                to="/signup"
                component={RouterLink} 
                variant="body2"  
                color="#6415ff"
            >
                Sign Up
            </Link>
        </Grid>
    </Grid>
    )
}

export default LoginHelp