import React from 'react'
import {countryList} from '../../utils'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'

const CountryDropDown = ({
    label, 
    value, 
    onChange, 
}) => {
    return  <FormControl fullWidth>
                <InputLabel required >
                    {label}
                </InputLabel>
                <Select 
                    
                    label={label}
                    value={value} 
                    onChange={onChange} 
                    sx={{backgroundColor: 'white'}}
                >
                    {countryList.map((c,i)=> 
                        <MenuItem key={i} value={c}>
                            {c}
                        </MenuItem>
                    )}
                </Select>
            </FormControl >
}

export default CountryDropDown