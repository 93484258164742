import React from "react"
import { Route, Switch } from "react-router-dom"

// mui
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

// pages
import AddPost from "./AddPost2"
import UpdatePost from "./UpdatePost1"
import PostList from "../pages/PostList"
import Profile from "../pages/Profile"
import Search from "../pages/Search"
import AuthorPostList from "../pages/AuthorPostList"
import Admin from "../pages/Admin"
import Stats from '../pages/Stats'
import LangStats from '../pages/LangStats'
import LangRss from '../pages/LangRss'
import ServiceDesk from '../pages/ServiceDesk'
import SupportSearch from '../pages/SupportSearch'
import Analytics from '../pages/Analytics'
import WebAnalytics from "./WebAnalytics"
import GoogleAnalytics from "./GoogleAnalytics"
import GoogleCloud from "./GoogleCloud"
// components
import AppBar1 from "../components/Nav/ResponsiveAppBar"

//protected routes pages
const routes = [ 
  { path: "/user/add_post",  component: AddPost, exact: true },
  { path: "/user/update_post/:id",  component: UpdatePost, exact: false },
  { path: "/user/author_posts/:member_id",  component: AuthorPostList, exact: false },
  { path: "/user/posts",  component: PostList, exact: true },
  { path: "/user/profile",  component: Profile, exact: true },
  { path: "/user/search_posts",  component: Search, exact: true },
  { path: "/user/admin",  component: Admin, exact: true },
  { path: "/user/stats",  component: Stats, exact: true },
  { path: "/user/support_desk",  component: ServiceDesk, exact: true },
  { path: "/user/lang_stats/:lang",  component: LangStats, exact: true },
  { path: "/user/lang_rss/:lang",  component: LangRss, exact: true },
  { path: "/user/support_search",  component: SupportSearch, exact: true },
  { path: "/user/analytics",  component: Analytics, exact: true },
  { path: "/user/google_analytics",  component: GoogleAnalytics, exact: true },
  { path: "/user/web_analytics",  component: WebAnalytics, exact: true },
  { path: "/user/google_cloud",  component: GoogleCloud, exact: true },
]

export default function User(){

  return  <Box sx={{ flexGrow: 1 }}>
            <AppBar1 />
            <Box sx={{height: 10}}></Box>
            <Container component="main" maxWidth="xl" >
              <Switch key="router">
                {routes.map(route => (
                  <Route
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                  />
                ))}
              </Switch>
            </Container>
          </Box>
}
