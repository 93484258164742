import React from 'react'

// mui
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'

// custom components
import TagsHeader from './TagsHeader'
import {LangTagsHeader} from './TagsHeader'


import { Divider } from '@mui/material'

const logoImagewb = 'https://langalearn.com/langa-logo-40.webp'
const logoImage = 'https://langalearn.com/langa-logo-40.png'

const BlogHeader = ({
    siteName,
    tags
}) => {
    const ok = tags && tags.length>0
    const langTags = ok ? tags.filter(t => t.language==true) : []
    const nTags = ok ? tags.filter(t => t.language==false) : []

    return <Grid container 
                justifyContent='space-between' 
                alignItems='center'
                spacing={1}
                sx={{mt: 1}}
            >
                <Grid item>
                    <Grid container 
                        alignItems='center'
                        spacing={1}
                    >
                        <Grid item>
                            <picture >
                                <source srcSet={logoImagewb} type="image/webp" />
                                <img src={logoImage}
                                    height='24'
                                    width='24'
                                    style={{
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        borderRadius: '10%',
                                    }}
                                    alt="langalogo" 
                                />
                            </picture>
                        </Grid>
                        <Grid item>
                            <Link
                                href='/'
                                variant="h6"
                                sx={{color: '#6415ff'}}
                            >
                                {siteName}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link
                                href='/blog'
                                variant="h6"
                                sx={{color: '#6415ff'}}
                            >
                                Blog
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item >
                    <LangTagsHeader
                        langTags={langTags}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <TagsHeader 
                        tags={nTags}
                    />
                </Grid>
            </Grid>
}

export default BlogHeader