import React from 'react'

// mui components
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';


// custom components
import AuthorItem from '../ListItems/AuthorItem'
import { LoadingButton } from '@mui/lab'

export default function ManageUsers({
  activeAuthors,
  createAuthor,
  updateAuthor,
  authorName,
  authorEmail,
  authorTwitter,
  handleAuthorName,
  handleAuthorEmail,
  handleAuthorTwitter,
  authorloading,
  authorLanguage,
  handleAuthorLanguage,
}){

    return(
      <Card>
      <CardContent>
        <Typography variant={'h5'} color='primary'>
          Authors
        </Typography>
      </CardContent>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {/* <CardContent>
        <Typography sx={{mb:2}}>
          Roles
        </Typography>
        <Typography sx={{mb:2}}>
          Writer - Add Post, View All Posts, Update Own Posts, Submit for Approval
        </Typography>
        <Typography sx={{mb:2}}>
          Editor - Add Post, Update All Posts, Approve Posts, Publish
        </Typography>
        <Typography >
          Admin - All Editor, Assign User Roles
        </Typography>
      </CardContent> */}
      <CardContent>
      <Grid container spacing={2}  justifyContent='space-between' alignItems='center'>
          <Grid item lg={3} md={6} xs={12}>
            <TextField 
              size='small'
              fullWidth
              required
              value={authorName} 
              onChange={handleAuthorName} 
              sx={{ width: '100%'}}
              label='Name'
            />

          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <TextField 
              size='small'
              fullWidth
              required
              value={authorEmail} 
              onChange={handleAuthorEmail} 
              sx={{ width: '100%'}}
              label='Email'
            />

          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <TextField 
              fullWidth
              size='small'
              required
              value={authorTwitter} 
              onChange={handleAuthorTwitter} 
              sx={{ width: '100%'}}
              label='Twitter'
            />
          </Grid>
          <Grid item lg={2} md={6} xs={9}>
            <TextField 
              fullWidth
              size='small'
              required
              value={authorLanguage} 
              onChange={handleAuthorLanguage} 
              sx={{ width: '100%'}}
              label='Language'
            />
          </Grid>
          <Grid item lg={1} xs={3}>
            <LoadingButton 
              onClick={createAuthor}
              variant='contained'
              startIcon={<AddIcon />}
              loading={authorloading}
            >
              Add 
            </LoadingButton>
          </Grid>
          </Grid>
          </CardContent>
           <Divider />
          <CardContent>
          <TableContainer >
              <Table fullWidth aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Language</TableCell>
                    <TableCell align="left">Twitter</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeAuthors && activeAuthors.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Button >
                          {row.name}
                        </Button>
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.language}</TableCell>
                      <TableCell align="left">{row.twitter}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </CardContent>
          {/* <Grid container spacing={2}  justifyContent='space-between' > */}
          {/* {activeAuthors.map(m => 
            <AuthorItem key={m.email} {...m}  /> 
          )} */}
          {/* {activeAuthors.length===0 &&
          <Grid item>
            <Typography >
              No authors
            </Typography> 
            </Grid>
          } */}
          {/* </Grid>
          </Grid> */}
        {/* </Grid> */}
      
{/*          

        <TextField 
          required
          value={siteName1} 
          onChange={handleSiteName} 
          sx={{ width: '100%'}}
          label='Name'
        />
        */}
        {/* <CardContent>
        {activeAuthors.map(m => 
          <AuthorItem key={m.email} {...m}  /> 
        )}
        {activeAuthors.length===0 &&
          <Typography >
            No authors
          </Typography> 
        }
      
      </CardContent> */}
    </Card>
  )
}