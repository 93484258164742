import React, {useMemo} from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

export default function MonthlyCountChart({sequences}){
  
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
  
const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Articles Per Month',
      },
    },
  };
  
  const labels = useMemo(() => sequences.map(m => m.month), [sequences])
  const data1 = useMemo(() => sequences.map(m => m.count), [sequences])

  const data = {
    labels,
    datasets: [
      {
        label: 'Articles Count',
        data: data1,
        backgroundColor: '#6415ff',
      },
    ],
  };

  return <Bar options={options} data={data} />
}
