import React from 'react'

// import { 
//   useQuery,
// } from "react-query"

// import {
//   allStats, 
// } from '../queries/statsQueries'

import GoogleCloudPage from '../components/Pages/GoogleCloudPage'

export default function GoogleCloud(){

  // const { 
  //   isLoading: isLoadingStats, 
  //   // isError, 
  //   // error, 
  //   data,
  //  } = useQuery('stats', () => allStats())

  //  const counts = !isLoadingStats ?  data.counts : []
  //  const scrapes = !isLoadingStats ?  data.stats : []

  return  <GoogleCloudPage
          
           
          />
  }
