import React from 'react'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

export default function NotFoundPage(){

  return (
    <Container component="main" maxWidth="sm">
      <Paper sx={{ padding: 2, marginTop: 2, backgroundColor: 'rgb(240, 246, 255)' }}> 
        <Grid container 
              justifyContent='center' 
              textAlign='center'
              alignItems='center'
              spacing={2}
        >
          <Grid item  xs={12}>
            <Typography variant="h4" >
              Page Not Found!
            </Typography>
          </Grid>
        </Grid>
      </Paper>  
    </Container>
  )
}