import React from "react"
import { Link, Typography } from "@mui/material"
import { langSwitch } from "../../utils"

const stripeLink = 'https://buy.stripe.com/28o14Naaz3A754k5kk'

const getMondly = (nativeLang) => {
    if(nativeLang==='en') return { text: "Mondly 90% discount", url:"https://www.tkqlhce.com/click-100779061-14099763"}
    if(nativeLang==='es') return  { text: "Mondly 90% discount", url:"https://www.anrdoezrs.net/click-100779061-14445591"}
    if(nativeLang==='fr') return { text: "Mondly 90% discount", url:"https://www.jdoqocy.com/click-100779061-14546632"}
    if(nativeLang==='de') return { text: "Mondly 90% discount", url:"https://www.kqzyfj.com/click-100779061-14546612"}
}

const  getTutorText= (nativeLang) => {
    if(nativeLang==='en') return  'Find a italki tutor. Click for intro discount!'
    if(nativeLang==='es') return  'Encuentra un italki tutor. ¡Haga clic para el descuento de introducción!'
    if(nativeLang==='fr') return  "Trouvez un italki tuteur. Cliquez pour une remise d'introduction!"
    if(nativeLang==='de') return  'Finden Sie einenitalki Nachhilfelehrer. Klicken Sie für den Einführungsrabatt!'
}


export const ITalkiLink = ({nativeLang}) => {
    const text = getTutorText(nativeLang)
    return <div style={{border: 'solid', borderRadius: '5px', padding: '10px', borderColor:'#6415ff'}}> 
                <Link  variant="body2" href="https://www.italki.com/affshare?ref=af17179493" target="_blank" >
                    {text}
                </Link>
            </div> 
}

// <a href="https://babbel.sjv.io/c/3977780/1072593/13589" target="_top" id="1072593"><img src="//a.impactradius-go.com/display-ad/13589-1072593" border="0" alt="" width="1417" height="307"/></a><img height="0" width="0" src="https://imp.pxf.io/i/3977780/1072593/13589" style="position:absolute;visibility:hidden;" border="0" />

export const BabbleLink = () => {
    return <div style={{border: 'solid', borderRadius: '5px', padding: '10px', borderColor:'#6415ff'}}> 
                <Link  variant="body2" href="https://babbel.sjv.io/c/3977780/1072593/13589" target="_blank" >
                    Learn with Babble - Discount!
                </Link>
            </div> 
}


export const StripeLink = () => {
    return <div style={{border: 'solid', borderRadius: '5px', padding: '10px', borderColor:'#6415ff'}}> 
                <Link  variant="body2" href={stripeLink} target="_blank" >
                    Support our work
                </Link>
            </div> 
}

export const MondlyLink = ({nativeLang}) => {
    const {text, url} = getMondly(nativeLang)
    return <div style={{border: 'solid', borderRadius: '5px', padding: '10px', borderColor:'#6415ff'}}> 
                <Link  variant="body2" href={url} target="_blank" >
                    {text}
                </Link>
            </div> 
}

export const MosaLingLink = () => {
    return <div style={{border: 'solid', borderRadius: '5px', padding: '10px', borderColor:'#6415ff'}}> 
                <Link  variant="body2" href='https://academy.mosalingua.com/mosaweb/?ref=1473' target="_blank" >
                    Learn a language with MosaLingua
                </Link>
            </div> 
}

export const LingoPieLink = () => {
    return <div style={{border: 'solid', borderRadius: '5px', padding: '10px', borderColor:'#6415ff'}}> 
                <Link  variant="body2" href='https://lingopie.com/?ref=evangoodwin1&utm_source=Goodwin+Learning&utm_medium=affiliate&utm_campaign=evangoodwin1&utm_term=evangoodwin1' target="_blank" >
                    Find video content with Lingopie
                </Link>
            </div> 
}

export const PreplyLink = ({ sourceLang, targetLang, }) => {
    const prep = getPreply({ sourceLang, targetLang, })
    return <div style={{border: 'solid', borderRadius: '5px', padding: '10px', borderColor:'#6415ff'}}> 
                <Link  variant="body2" href= {prep.url} target="_blank" >
                    {prep.title}
                </Link>
            </div> 
}



// <a href="https://media.go2speed.org/brand/files/preplyaffiliate/14/017_AFFILIATE-TUTOR_AM_US_C1_728x901.jpg" target="_blank">https://media.go2speed.org/brand/files/preplyaffiliate/14/017_AFFILIATE-TUTOR_AM_US_C1_728x901.jpg</a>
// const getBookList = ({
//     sourceLang, 
//     targetLang,
// }) => {
//     if(sourceLang==='en' && targetLang==='es'){
//         return 'spanish-for-english-speakers'
//     }
//     if(sourceLang==='en' && targetLang==='de'){
//         return 'german-for-english-speakers'
//     }
//     if(sourceLang==='en' && targetLang==='fr'){
//         return 'french-for-english-speakers'
//     }

//     if(sourceLang==='es' && targetLang==='en'){
//         return 'ingeles-por-hispanohablantes'
//     }
//     if(sourceLang==='es' && targetLang==='de'){
//         return 'aleman-por-hispanohablantes'
//     }
//     if(sourceLang==='es' && targetLang==='fr'){
//         return 'frances-por-hispanohablantes'
//     }

//     if(sourceLang==='fr' && targetLang==='es'){
//         return 'espagnol-pour-francophones'
//     }
//     if(sourceLang==='fr' && targetLang==='en'){
//         return 'allemande-pour-francophones'
//     }
//     if(sourceLang==='fr' && targetLang==='en'){
//         return 'anglaise-pour-francophones'
//     }

//     if(sourceLang==='de' && targetLang==='es'){
//         return 'spanisch-fur-deutschsprachige'
//     }
//     if(sourceLang==='de' && targetLang==='en'){
//         return 'englisch-fur-deutschsprachige'
//     }
//     if(sourceLang==='de' && targetLang==='fr'){
//         return 'franzosisch-fur-deutschsprachige'
//     }
// }

const getPreply = ({
    sourceLang, 
    targetLang,
}) => {
    const language = langSwitch(targetLang)

    // EN
    if(sourceLang==='en' && targetLang==='es'){
        return {
            title: `50% off -  Online ${language} tutors`,
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=280'
        }
    }
    if(sourceLang==='en' && targetLang==='de'){
        return  {
            title: `50% off -  Online ${language} tutors`,
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=285',
        }
    }
    if(sourceLang==='en' && targetLang==='fr'){
        return {
            title: `50% off -  Online ${language} tutors`,
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=336' 
        }
    }
    if(sourceLang==='en' && targetLang==='it'){
        return {
            title: `50% off - Online ${language} tutors`,
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643'
        }
    }
    if(sourceLang==='en' && targetLang==='pt'){
        return {
            title: `50% off - Online ${language} tutors`,
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=666'
        }
    }

    // ES
    if(sourceLang==='es' && targetLang==='en'){
        return {
            title: '50 % de descuento - Clases de inglés online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=284'
        }
    }

    if(sourceLang==='es' && targetLang==='de'){
        return {
            title: '50 % de descuento - Clases de alemán online',
            url:  'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=274'
        }
    }
    if(sourceLang==='es' && targetLang==='fr'){
        return {
            title: '50 % de descuento - Clases de francés online',
            url:  'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=274'
        }
    }
    if(sourceLang==='es' && targetLang==='it'){
        return {
            title: '50 % de descuento - Clases de italiano online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=274'
        }
    }
    if(sourceLang==='es' && targetLang==='pt'){
        return {
            title: '50 % de descuento - Clases de portugués online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=274'
        }
    }

    // FR
    if(sourceLang==='fr' && targetLang==='es'){
        return {
            title: 'Réduction de 50% - Clases de español online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=271'
        }
    }
    if(sourceLang==='fr' && targetLang==='en'){
        return {
            title: 'Réduction de 50% - Clases de anglais online',
            url:  'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=286'
        }
    }
    if(sourceLang==='fr' && targetLang==='de'){
        return {
            title: 'Réduction de 50% - Clases de allemand online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=271'
        }
    }
    if(sourceLang==='fr' && targetLang==='it'){
        return {
            title: 'Réduction de 50% - Clases de italiano online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=271'
        }
    }
    if(sourceLang==='fr' && targetLang==='pt'){
        return {
            title: 'Réduction de 50% - Clases de portugués online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=271'
        }
    }


    // DE
    if(sourceLang==='de' && targetLang==='es'){
        return {
            title: '50% Rabatt - Spanisch Unterricht',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=272'
        }
    }
    if(sourceLang==='de' && targetLang==='en'){
        return {
            title: '50% Rabatt - Englisch Unterricht',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=283'
        }
    }
    if(sourceLang==='de' && targetLang==='fr'){
        return {
            title: '50% Rabatt - Französisch Unterricht',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=272'
        }
    }
    if(sourceLang==='de' && targetLang==='it'){
        return {
            title: '50% Rabatt - Italienisch Unterricht',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=272'
        }
    }
    if(sourceLang==='de' && targetLang==='pt'){
        return {
            title: '50% Rabatt - Portugiesisch Unterricht',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=272'
        }
    }

    // it
    if(sourceLang==='it' && targetLang==='es'){
        return {
            title: 'Lo sconto di 50% - Lezioni spagnolo',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=273'
        }
    } 
    if(sourceLang==='it' && targetLang==='en'){
        return {
            title: 'Lo sconto di 50% - Lezioni inglese',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=289'
        }
    }
    if(sourceLang==='it' && targetLang==='fr'){
        return {
            title: 'Lo sconto di 50% - Lezioni francese',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=273'
        }
    }
    if(sourceLang==='it' && targetLang==='de'){
        return {
            title: 'Lo sconto di 50% - Lezioni tedesco',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=273'
        }
    }
    if(sourceLang==='it' && targetLang==='pt'){
        return {
            title: 'Lo sconto di 50% - Lezioni portoghese',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=273'
        }
    }

    // pt
    if(sourceLang==='pt' && targetLang==='es'){
        return {
            title: '50% de desconto - Aulas de espanhola online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=275'
        }
    }
    if(sourceLang==='pt' && targetLang==='en'){
        return {
            title: '50% de desconto - Aulas de inglês online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=634'
        }
    }
    if(sourceLang==='pt' && targetLang==='fr'){
        return {
            title: '50% de desconto - Aulas de francesa online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=275'
        }
    }
    if(sourceLang==='pt' && targetLang==='de'){
        return {
            title: '50% de desconto - Aulas de alemão online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=275'
        }
    }
    if(sourceLang==='pt' && targetLang==='it'){
        return {
            title: '50% de desconto - Aulas de italiano online',
            url: 'http://tracking.preply.com/aff_c?offer_id=14&aff_id=4643&url_id=275'
        }
    }
}

export default function AffiliateLinks(){
    return  <div>
                <Typography sx={{mb: 2}}>
                    Affiliate Links
                </Typography>
                 <ITalkiLink nativeLang='en' />
                <div style={{height: 10}} />
                <LingoPieLink />
                <div style={{height: 10}} />
                <MondlyLink nativeLang='en' />
                <div style={{height: 10}} />
                <MosaLingLink />
                <div style={{height: 10}} />
                <BabbleLink />
                {/* <div style={{height: 10}} /> */}
                {/* {member.length>0 &&
                <PreplyLink sourceLang='en' targetLang={lang} />} */}
                <div style={{height: 10}} />
                <StripeLink />
            </div>
}

// export const LangBooks = ({targetLang}) => {
//     const language = langSwitch(targetLang)
//     return  <div style={{border: 'solid', borderRadius: '5px', padding: '10px', borderColor:'#6415ff'}}> 
//                  <Link component={RouterLink}  variant="body2" to={`/user/bookstore/${targetLang}`} >
//                     {`Find books to accerlate to your ${language}!`}
//                 </Link>
//             </div>
// }

// export const BookstoreLink = ({targetLang}) => {
//     const language = langSwitch(targetLang)
//     return <div style={{border: 'solid', borderRadius: '5px', padding: '10px', borderColor:'#6415ff'}}> 
//                 <Link component={RouterLink}  variant="body2" to={`/user/bookstore/${targetLang}`} >
//                     {`Find books to accerlate to your ${language}!`}
//                 </Link>
//             </div> 
// }