import React from 'react';
import Link from '@mui/material/Link'

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const MainFeaturedPost =({ post })=>
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        minHeight:'20%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${post.post_image})`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.3)',
        }}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            sx={{
              position: 'relative',
              p: 3,
            }}
          >
            <Link href='' component="h4" variant="h4" color="#eeeeee" gutterBottom> 
                {post.title}
            </Link>
            <Typography variant="h6" color="#eeeeee" paragraph sx={{ display: { xs: 'none', md: 'block' } }}>
              {post.summary}
            </Typography>
            <Typography  variant='body2' color="#eeeeee" >
              <Link href=''  style={{color: "#eeeeee"}} >
                Read more {`>`}
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>

export default MainFeaturedPost