import React from "react"

// packages
import { useParams } from "react-router-dom"

// DATA

// client
import { 
  useQuery,
} from "react-query"


// queries
import {
  getTags, 
  getPostsAuthor,
} from '../queries/blogQueries'


// UI

// custom componets
import PostListPage from '../components/Pages/PostListPage'

export default function AuthorPostList(){

   // DATA

   // fetch

    const { 
      member_id, 
    } = useParams()

    const { isLoading, data: posts } = useQuery('posts', () => getPostsAuthor({member_id}))
    const { data: tags } = useQuery('tags', () => getTags())

    const posts1 = !isLoading ? posts: []

    return <PostListPage
            title='Posts'
            posts={posts1}
            tags={tags}
          />
}