import React from 'react'

import RecentStats from '../Stats/RecentStats'
import AllLangStats from '../Stats/AllLangStats'

export default function StatsPage({
  counts,
  scrapes,
}){
  return (
      <main > 
        {counts &&
          <AllLangStats counts={counts} />
        }
        {scrapes &&
          <RecentStats scrapes={scrapes}  />
        }   
      </main>
    )
}
