import { useQuery } from "react-query"

 import {queryClient} from '../index'

import {slugify} from '../utils'
 
import {supabase} from "../supabase"

import {getAuthUser} from './memberQueries'

const table = 'blogs'

export const postQuery = `
    id,
    title, 
    article_text,
    blocks,
    summary,
    lang,
    published_date,
    post_image,
    featured,
    published,
    slug,
    facebook,
    tweet,
    youtube,
    instagram,
    tiktok,
    video,
    profile(
        id,
        name
    ),
    authors(
        id,
        name
    ),
    post_tags(
        id,
        tags(
            id,
            tag_name,
            tag_color,
            language
        )
    )
    tags(
        id,
        tag_name,
        tag_color
    )`

export const createPost =  async ({
    title,
    lang, 
    featured,
    summary,
    article_text,
    post_image,
    postTags,
    blocks,
    postAuthor,
}) => {
    const now = new Date()
    const slug = slugify(title)
    const user = await getAuthUser()
    const aid = user.id ?? '';
    if(user){
        const { data, error } = await supabase
        .from(table)
        .insert({
           title,
           slug,
           lang, 
           featured,
           summary,
           article_text,
           blocks,
           published_date: now,
           post_image,
           aid,
           author: postAuthor,
           published: false
        }).select('id')

        if(error) throw new Error(error.message)

        const post_id = data[0]['id']

        const tagids = postTags.map(p => ({post_id, tag_id: p}))
   
        const { error: error1 } = await supabase
            .from('post_tags')
            .insert(tagids)

        if(error1) throw new Error(error1.message)

        return data
    } else {
        throw new Error('No user!')
    }
   

}

export const updatePost = async update => {
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', update.id)

    if(error) throw new Error(error.message)

    return data
}

export const getPost = async ({id}) => {
    const { data, error } = await supabase
      .from(table)
      .select(`
        id,
        published_date,
        title,
        summary,
        article_text,
        blocks,
        lang,
        featured,
        post_image,
        published,
        slug,
        facebook,
        tweet,
        youtube,
        instagram,
        tiktok,
        video,
        profile(
            name
        ),
        author(
            id,
            name
        ),
        post_tags(
            id,
            tags(
                id,
                tag_name,
                tag_color,
                language
            )
        )
        tags(
            id,
            tag_name,
            tag_color
        )
      `)
      .eq('id', id)
      .single()
  
    if(error) throw new Error(error.message)
  
    if(!data) throw new Error("Post not found")
    return data
  }

export const getPosts = async () => {
    const { data, error } = await supabase
    .from(table)
    .select(postQuery)
    .order('published_date',{ascending: false})
    .limit(20)

    if(error) throw new Error(error.message)

    if(!data)  throw new Error("Cluster not found")

    return data
}

export const getPublishedPosts = async (page) => {
    const start = page*10
    const end = start+9
    const { data, error, count } = await supabase
        .from(table)
        .select(postQuery, {count: 'exact'})
        .eq('published', true)
        .order('published_date',{ascending: false})
        .range(start, end)
    
    if(error) throw new Error(error)

    if(!data)  throw new Error("Posts  not found")

    return {data, count}
}

export const getUnpublishedPosts = async (page) => {
    const start = page*10
    const end = start+9
    const { data, error, count} = await supabase
    .from(table)
    .select(postQuery, {count: 'exact'})
    .eq('published', false)
    .order('inserted_at',{ascending: false})
    .range(start, end)

    if(error) throw error.message

    if(!data)  throw new Error("Posts not found")

    return {data, count}
}


export const getRecentPosts = async () => {
    const { data, error } = await supabase
    .from(table)
    .select(postQuery)
    .eq('published', true)
    .order('published_date',{ascending: false})
    .limit(5)

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Cluster not found")

    return data
}

export const getPostsAuthor = async ({member_id}) => {
    const { data, error } = await supabase
    .from(table)
    .select(postQuery)
    .eq('author_id', member_id)

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Cluster not found")

    return data
}

export const uploadImage = async ({
    filePath, 
    file,
}) => {
    let { error: uploadError, } = await supabase.storage
    .from('blogimages')
    .upload(filePath, file, {
        cacheControl: '3600',
        upsert: false
    })
    if(uploadError) throw uploadError

}

export const getBlogImages = async () => {
    const { data, error } = await supabase.storage.from('blogimages').list()

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Images not found")

    return data
}

export const getTags = async () => {
    const { data, error } = await supabase
        .from('tags')
        .select(`
            id,
            tag_name,
            tag_color,
            language
            `)
        .order('tag_name',{ascending: true})

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Cluster not found")

    return data
}

export const createTag =  async ({
    tagName,
    tagColor,
    author_id
}) => {
    const { data, error } = await supabase
    .from('tags')
    .insert({
        tag_name: tagName,
        tag_color: tagColor,
        author_id,
})

    if(error) throw new Error(error.message)

    return data
}
 
export const usePosts = () => useQuery('posts', () => getPosts())

export const prefetchPosts = async () => await queryClient.prefetchQuery('posts', getPosts())
