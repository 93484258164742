import React from 'react'

// mui
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

const AboutCard = ({
    siteDescription
}) => 
        <Paper elevation={0} style={{ padding: 20, backgroundColor: '#eeeeee' }}>
            <Typography variant="h6" gutterBottom color='#6415ff' >
                About
            </Typography>
            <Divider sx={{mt: 1, mb: 1}} /> 
            <Typography>
                {siteDescription}
            </Typography>
            
        </Paper>

export default AboutCard