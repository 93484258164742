import _ from "lodash"
 
import {supabase} from "../supabase"

export const postAuth1 = async (url = '', data = {}) => {
  const token = process.env.REACT_APP_SUPABASE_ANON_KEY
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'no-cors', // no-cors, *cors, same-origin
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export const getLangCounts = async (lang) => {
    const { data, error } = await supabase
      .from('word_counts')
      .select('*')
      .eq('lang', lang)
  
    if(error) {
      throw new Error(error.message)
    }
  
    if(!data) {
      throw new Error("Counts not found")
    }
  
    return data[0]['count']
};

const getMonth = (timestamp) => {
  const ts = new Date(timestamp)
  const month = ts.getMonth()
  const year = ts.getFullYear()
    if (month===0) {
      return {month: "January " + year , number: 1};
    }
    if (month===1) {
      return {month: "February " + year, number: 2};
    }
    if (month===2) {
      return {month: "March " + year, number: 3};
    }
    if (month===3) {
      return {month: "April " + year, number: 4};
    }
    if (month===4) {
      return {month: "May " + year, number: 5};
    }
    if (month===5) {
      return {month: "June " + year, number: 6};
    }
    if (month===6) {
      return {month: "July " + year, number: 7};
    }
    if (month===7) {
      return {month: "August " + year, number: 8};
    }
    if (month===8) {
      return {month: "September " + year, number: 9};
    }
    if (month===9) {
      return {month: "October " + year, number: 10};
    }
    if (month===10) {
      return {month: "November " + year, number: 11};
    }
    if (month===11) {
      return {month: "December " + year, number: 12};
    }
  }
 
const getMonthArt = (monthlyArts) => {
  return  monthlyArts.map((m) => ({
    ...getMonth(m.arts_per_month),
    count: parseInt(m.art_count),
  })).sort(function(a, b) {
    return a["number"] - b["number"];
  })
  .map((m) => ({month: m.month, count: m.count}))
}
  
export const getCounts = async (lang) => {
    const { data, error } = await supabase
      .from('word_counts')
      .select('*')
      .order('count', {ascending:false})
  
    if(error) throw new Error(error.message)
  
    if(!data)  throw new Error("Counts not found")
  
    return data
};
      
export const getStats = async () => {   
    const { data, error } = await supabase
      .from('scraping')
      .select('*')
      .order('inserted_at', {ascending: false})
      .limit(10)
  
    if(error) {
      throw new Error(error.message)
    }
  
    if(!data) {
      throw new Error("Counts not found")
    }
  
    return data
};

/**
 * Number of articles downloaded for given date
 * @param {int} art_num number of articles
 * @param {DateTime} inserted_at Article download date
 * @return {object} {art_num: art_num, day
 */
 export function getDate({
    art_num, 
    inserted_at,
}) { // get date
    const day = new Date(inserted_at).getDate();
    return {art_num: art_num, day};
}

/**
* Reducer
* @param {int} accumulator The first number.
* @param {int} currentValue The second number.
* @return {int} The second number.
*/
const reducer = (accumulator, currentValue) => accumulator + currentValue; // get add up

/**
* Get totals for a given field.
* @param {array} array Array of dicts
* @param {string} totalfield field to total
* @param {string} groupfield field to group
* @return {array} 
*/
function getTotals(array, totalfield, groupfield) {
  const groupedResults = _.groupBy(array, groupfield);
  const totals = [];
  for (const [key, value] of Object.entries(groupedResults)) {
    const counts = value.map((v) => parseInt(v[totalfield]));
    const total = {day: key, total: counts.reduce(reducer)};
    totals.push(total);
  }
  return totals;
}

/**
* Number of articles downloaded in a month for a language
* @param {array} counts Array of articles downloaded per day.
* @return {object} Total articles downloaded for given month
*/
export function getMonthCounts(
    counts
) { 
    const dateCounts = counts.map((s) => (getDate(s)));
    return getTotals(dateCounts, "art_num", "day");
}

export const getLangStats = async (
    lang,
    ) => {
    const today = new Date();
    const startDate = new Date(new Date().setDate(today.getDate() - 30))
    const { data, error } = await supabase
        .from('scraping')
        .select('*')
        .eq('lang', lang)
        .gte('inserted_at', startDate.toISOString())
        .order('inserted_at', {ascending: false})

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Counts not found")

    return data
  };
  
export const addFeed = async ({
    lang, 
    link, 
    country, 
}) => {
    const { data, error } = await supabase
    .from('rss_feeds')
    .insert({
        feed: link, 
        lang, 
        country, 
    }, {returning: 'minimal'})

    if(error) throw new Error(error.message)

    return data
}

export const updateFeed = async (update) => {
  const { data, error } = await supabase
    .from('rss_feeds')
    .update(update, {returning: 'minimal'})
    .eq('id', update.id)

  if(error) throw new Error(error.message)

  return data
}

export const deleteFeed = async (id) => {
  const { data, error } = await supabase
    .from('rss_feeds')
    .delete({returning: 'minimal'})
    .eq('id', id)

  if(error) throw new Error(error.message)

  return data
}

export const getLangRssFeeds = async (lang) => {
  const { data, error } = await supabase
    .from('rss_feeds')
    .select('*')
    .eq('lang', lang)
    .order('id')

  if(error) throw new Error(error.message)

  if(!data) throw new Error("Counts not found")

  return data
};

export const getMonthylyArts = async (lang) => {
    const {data, error} = await supabase
      .rpc("monthly_lang_arts", {
        sellang: lang,
      });
  
    if (error) throw new Error(error.message);
    
    return getMonthArt(data);
  }

/**
* Gets scraping stats
* @return {object} {stats, counts}
*/
export const allStats = async () => {
    const stats = await getStats();
    const counts = await getCounts();
    return {
        stats,
        counts,
    };
}

/**
* Gets scraping stats for single language
* @param {string} lang field to total
* @return {object} {stats, recent, monthCount}
*/
export const langStats = async ({
    lang
}) => {
    const count = await getLangCounts(lang);
    const stats = await getLangStats(lang);
    const monthCount = getMonthCounts(stats, lang);
    const recent = stats.slice(0, 10);
    return {
        count,
        recent,
        monthCount,
    };
}

// export const getMonthylyArts1 = async (
//   lang,
//   ) => {
//   try {
//     const data = {lang}
//     return await postAuth(statsUrl, data)
//   } catch(error){
//     throw error;
//   }
// }