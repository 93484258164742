import React, { useState } from "react"

// DATA

// clients
import { supabase } from "../supabase"

// UI

// custom components
import SupportSearchPage from '../components/Pages/SupportSearchPage'

export default function SupportSearch(){

    // DATA

    // state
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)
    const [ searchResults, setSearchResults ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ email, setEmail ] = useState('')

    const handleEmail = event => setEmail(event.target.value)
    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    // functions
    const searchServeRequests = async () => {
      setLoading(true)
      const { data, error } = await supabase
      .from('service_desk')
      .select("*")
      .eq('email', email)
      .order('created_at', {ascending: false})

      if(error) {
        showSnack('error','Search error!')
      }
    
      if(!data) {
        showSnack('error','Search error!')
      }
      setLoading(false)
      setSearchResults(data)
    }

    return <SupportSearchPage
            email={email}
            loading={loading}
            searchServeRequests={searchServeRequests}
            handleEmail={handleEmail}
            snack={snack}
            msgType={msgType}
            alertMsg={alertMsg}
            handleClose={handleClose}
            searchResults={searchResults}
          />
}