import  React from 'react'

// UI

// Mui
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'

// icons
import BarChartIcon from '@mui/icons-material/BarChart';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

// custom components
import SnackAlert from '../Dialogs/SnackAlert'
import AppBarButton from '../Buttons/AppBarButton'
import Logo from '../ImageElements/Logo'

const ResponsiveAppBarComponents = ({
    buttons,
    siteTitle,
    publisher,
    anchorElNav,
    alertMsg,
    msgType,
    snack,
    handleClose,
    handleOpenNavMenu,
    handleCloseNavMenu,
    publishPosts,
    logout,
    role,
}) => 
    <AppBar position="static" sx={{mb:2}}>
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                    <Logo size={3}  />
                </Box>
                {/* <Typography
                    variant="h6"
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 400,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    {siteTitle}
                </Typography> */}
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {buttons.map((p) => (
                            <MenuItem 
                                key={p.title}   
                                component="a" 
                                href={p.href} 
                                style={{ textDecoration: 'none', display: 'block' }}
                            >
                                {p.title}
                            </MenuItem>
                        ))}
                         {role==='admin' && 
                            <MenuItem  
                                component="a" 
                                href='/user/stats' 
                                sx={{ textDecoration: 'none', display: 'block' }}
                            >
                                Stats
                            </MenuItem>
                        }
                        {role==='admin' && 
                            <MenuItem  
                                component="a" 
                                href='/user/admin' 
                                sx={{ textDecoration: 'none', display: 'block' }}
                            >
                                Admin
                            </MenuItem>
                        }  
                        {publisher &&
                            <MenuItem  
                                onClick={publishPosts}
                                sx={{ textDecoration: 'none', display: 'block' }}
                            >
                                Publish
                            </MenuItem>
                        }
                    </Menu>
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                    <Logo size={3}  />
                </Box>
                {/* <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href=""
                    sx={{
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 400,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                    >
                    {siteTitle}
                </Typography> */}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}> 
                    {buttons.map(b => 
                        <AppBarButton {...b} key={b.title} />
                    )}
                    {role==='admin' && 
                        <AppBarButton 
                            title='Admin'
                            href= '/user/admin'
                            icon={<AdminPanelSettingsIcon />}
                        /> 
                    }
                    {role==='admin' && 
                        <AppBarButton 
                            title='Stats'
                            href= '/user/stats'
                            icon={<BarChartIcon />}
                        /> 
                    }
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                    <Button
                        sx={{mr:2}}
                        color="error"  
                        variant="contained"  
                        size='small' 
                        onClick={logout}
                    >
                        Logout
                    </Button>
                </Box>
            </Toolbar>
        </Container>
        <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
        /> 
    </AppBar>

export default ResponsiveAppBarComponents
