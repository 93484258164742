import React from "react"

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

export const tabColors = [
    '#f44336', // 
    '#e91e63',
    '#9c27b0', //
    '#673ab7',
    '#3f51b5', //
    '#2196f3', //
]


const ColorDropDown = ({
    tagColor,
    handleTagColor,
}) => 
    <FormControl  fullWidth  sx={{minWidth: 150}}>
        <InputLabel >
           Color
        </InputLabel>
        <Select
            label='Color'
            value={tagColor}
            onChange={handleTagColor}
            sx={{backgroundColor: 'white'}}
        >
        {tabColors.map(t => 
            <MenuItem key={t} value={t}>
                <Box sx={{
                        backgroundColor: t, 
                        height: 24, 
                        width: 24, 
                        borderRadius:1, 
                        mr:1,
                    }} />
            </MenuItem>
        )}
        </Select>
    </FormControl>

export default  ColorDropDown