import React from 'react'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Chip from '@mui/material/Chip'
import CategoryChip from './CategoryChip'

import LinkIcon from '@mui/icons-material/Link'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon  from '@mui/icons-material/Instagram'
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';

import FlagRound from '../PostPreview/FlagRound'
import {TikTokIcon} from '../ImageElements/BlogIcons'

export default function PostListItem({
    id,
    title,
    published_date,
    profile,
    authors,
    lang,
    post_tags,
    featured,
    published,
    tweet,
    facebook,
    youtube,
    instagram,
    tiktok,
    slug,
    video,
}){
    const date = moment(published_date).format('MMMM Do YYYY, h:mm a')
    const sep = featured || published
    const baseUrl = 'https://langalearn.com/post'
    const url = `${baseUrl}/${slug}`
    return  <Grid item xs={12}>
                <Typography >
                    <Link href={`/user/update_post/${id}`}  style={{color: '#6415ff'}}>
                        {title}
                    </Link>
                </Typography>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item  xs={12}>
                        <Typography variant="body1">
                            {date} by <Link sx={{color: '#5d4037'}} href={`/user/author_posts/${authors.id}`}>{authors.name}</Link>
                        </Typography> 
                    </Grid>
                    {post_tags.length> 0 && post_tags.map(t => 
                        <Grid item key={t.id}>
                            <CategoryChip  {...t.tags}   />
                        </Grid>
                    )}
                    {sep && 
                        <Grid item>
                            |
                        </Grid>
                    }
                    <Grid item>
                        {featured && <Chip label='Featured' size='small' color='info'  sx={{ color:'white'}} />}
                    </Grid>
                    <Grid item>
                        {published && <Chip label='Published' size='small' color='success' sx={{ color:'white'}} />}
                    </Grid>
                    {published && 
                        <Grid item>
                            <Link href={url} target='_blank'>
                                <LinkIcon  />
                            </Link>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <FlagRound flagSize='xsmall' lang={lang}/>
                            </Grid>
                            <Grid item>
                                |
                            </Grid>
                            <Grid item>
                                <VideoCameraBackIcon color={video ? 'primary' : 'disabled'} />
                            </Grid>
                            {video &&
                            <Grid item>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item>
                                        <TwitterIcon color={tweet ? 'primary' : 'disabled'} />
                                    </Grid>
                                    <Grid item>
                                        <FacebookIcon color={facebook ? 'primary' : 'disabled'}   />
                                    </Grid>
                                    <Grid item>
                                        <YouTubeIcon color={youtube ? 'primary' : 'disabled'}  />
                                    </Grid>
                                    <Grid item>
                                        <InstagramIcon color={instagram ? 'primary' : 'disabled'}  />
                                    </Grid>
                                    <Grid item>
                                        <TikTokIcon color={tiktok ? '#6415ff' : 'lightgrey'} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
          
}