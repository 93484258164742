import React from 'react'

import {langSwitch} from '../../utils'

import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar';

export default function CategoryChip({
    language, 
    tag_name, 
    tag_color,
 }){
    return language ? 
        <Chip label={langSwitch(tag_name)} size='small'  avatar={<Avatar alt={tag_name} src={`/${tag_name}.svg` } />} /> 
        : 
        <Chip label={tag_name} size='small' sx={{backgroundColor: tag_color, color:'white'}} />
}