import React from "react";
import moment from 'moment'
import { langSwitch } from '../../utils'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'

import FlagRound from "../ImageElements/FlagRound"

const ScrapeRow = ({
  lang, 
  art_num, 
  mean, 
  inserted_at,
  dl_time,
 }) => {
  const language = langSwitch(lang)
  return(
    <TableRow hover={true}>
      <TableCell component="th" scope="row">
        <Grid container alignContent="center" spacing={1}>
          <Grid item>
            <FlagRound
              lang={lang}
              flagSize='xsmall'
            />
          </Grid>
          <Grid item>
            <Typography >
              {language}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="right">
        {dl_time}
      </TableCell>
      <TableCell align="right">
        {art_num}
      </TableCell>
      <TableCell align="right">
        {Math.round(mean)}
      </TableCell>
      <TableCell align="right">
       {moment(inserted_at).format('MMM Do YY, h:mm a')}
      </TableCell>
    </TableRow>
  )
}

export default function RecentStats({scrapes}){
    return(
        <Card sx={{m:1}}>
            <CardContent style={{margin:10}}>
              <TableContainer  >
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Lang</TableCell>
                      <TableCell align="right">DL Times</TableCell>
                      <TableCell align="right">New</TableCell>
                      <TableCell align="right">Mean</TableCell>
                      <TableCell align="right">Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {scrapes.map((r,i) => 
                    <ScrapeRow key={i} {...r} />
                  )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
  )
}