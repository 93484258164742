import React from 'react'

// mui components
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'

// custom components
import MemberItem from '../ListItems/MemberItem'

export default function ManageUsers({
  newMembers,
  existingMembers,
  handleUpdate,
}){

    return(
      <Card>
      <CardContent>
        <Typography variant={'h5'} color='primary'>
          Users
        </Typography>
      </CardContent>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <CardContent>
        <Typography sx={{mb:2}}>
          Roles
        </Typography>
        <Typography sx={{mb:2}}>
          Writer - Add Post, View All Posts, Update Own Posts, Submit for Approval
        </Typography>
        <Typography sx={{mb:2}}>
          Editor - Add Post, Update All Posts, Approve Posts, Publish
        </Typography>
        <Typography >
          Admin - All Editor, Assign User Roles
        </Typography>
      </CardContent>
      <CardContent>
      <Typography color='primary' sx={{mb:2}}>
          New Users - Assign Role
        </Typography>
      <Grid container justifyContent='space-between' alignItems='center'>
        {newMembers.map(m => 
          <MemberItem key={m.email} {...m} updateRole={handleUpdate} /> 
        )}
        {newMembers.length===0 &&
          <Typography >
            No new members
          </Typography> 
        }
        </Grid>
      </CardContent>
      <CardContent>
        <Typography color='primary' sx={{mb:2}}> 
          Existing Users
        </Typography>
    
      <Grid container justifyContent='space-between' alignItems='center'>
        {existingMembers.map(m => 
          <MemberItem key={m.email} {...m} updateRole={handleUpdate}  /> 
        )}
        </Grid>
      </CardContent>
    </Card>
  )
}