import React from "react"

import { langSwitch } from "../../utils"

// mui
import Grid from '@mui/material/Grid'
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import DeleteIcon from "@mui/icons-material/Delete"
import { IconButton } from "@mui/material"

export default function PostTag({
    tags, 
    tag_id,
    deletePostTag,
  }){
    const tag = tags.filter(t => t.id==tag_id)
    const tag_name =  tag.length>0 ? tag[0].tag_name : ''
    const tag_color = tag.length>0 ?tag[0].tag_color : ''
    const language = tag.length>0 ? tag[0].language : false
    const tagName = language ? langSwitch(tag_name) : tag_name
    const langurl = `/${tag_name}.svg`
    const deleteTag = () => deletePostTag(tag_id)
    return  <Grid item>
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <Typography>
                    {tagName}
                  </Typography>
                </Grid>
                <Grid item>
                  {
                  language ?
                    <img src={langurl}
                        style={{ 
                          height: 20, 
                          width: 20, 
                          borderRadius: 15, 
  
                        }} 
                    />
                    :
                    <Box sx={{
                      backgroundColor: tag_color, 
                      height: 20, 
                      width: 20, 
                      borderRadius:1, 
                      mr:1,
                      }} 
                    />
                  }
              </Grid>
              <Grid item>
                <IconButton size="small"  onClick={deleteTag} >
                  <DeleteIcon color="error" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
  }
  