import {supabase} from "../supabase"

const table = 'service_desk'

export const updateServiceRequest = async update => {
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', update.id)

    if(error) throw new Error(error.message)

    return data
}

export const getServiceRequests = async () => {
    const { data, error } = await supabase
    .from(table)
    .select("*")
    .order('created_at',{ascending: false})
    .limit(10)

    if(error) throw new Error(error.message)
    if(!data) return []

    return data
}

// export const getRecentPosts = async () => {
//     const { data, error } = await supabase
//     .from(table)
//     .select(postQuery)
//     .eq('published', true)
//     .order('published_date',{ascending: false})
//     .limit(5)

//     if(error) {
//         console.log(error)
//         throw new Error(error.message)
//     }

//     if(!data) {
//         throw new Error("Cluster not found")
//     }
//     console.log(data)
//     return data
// }

// export const getPostsAuthor = async ({member_id}) => {
//     const { data, error } = await supabase
//     .from(table)
//     .select(postQuery)
//     .eq('author_id', member_id)

// if(error) {
//     console.log(error)
//     throw new Error(error.message)
// }

// if(!data) {
//     throw new Error("Cluster not found")
// }

// return data
// }

// export const uploadImage = async ({
//     filePath, 
//     file,
// }) => {
//     let { error: uploadError, data } = await supabase.storage
//     .from('blogimages')
//     .upload(filePath, file, {
//         cacheControl: '3600',
//         upsert: false
//       })
//     console.log(uploadError)
//     if(uploadError) throw uploadError
//     console.log(data)
// }

// export const getBlogImages = async () => {
//     const { data, error } = await supabase.storage.from('blogimages').list()

//     if(error) {
//         throw new Error(error.message)
//     }

//     if(!data) {
//         throw new Error("Images not found")
//     }

//     return data
// }

// export const getTags = async () => {
//     const { data, error } = await supabase
//         .from('tags')
//         .select(`
//             id,
//             tag_name,
//             tag_color
//             `)
//         .order('tag_name',{ascending: true})

//     if(error) {
//         console.log(error)
//         throw new Error(error.message)
//     }

//     if(!data) {
//         throw new Error("Cluster not found")
//     }

//     return data
// }

// export const createTag =  async ({
//     tagName,
//     tagColor,
//     author_id
// }) => {
//     const { data, error } = await supabase
//     .from('tags')
//     .insert({
//         tag_name: tagName,
//         tag_color: tagColor,
//         author_id,
// })

//     if(error) {
//         throw new Error(error.message)
//     }

//     return data
// }

// export const getPost = async ({id}) => {
//     const { data, error } = await supabase
//       .from(table)
//       .select('*')
//       .eq('id', id)
//       .single()
  
//     if(error) {
//       throw new Error(error.message)
//     }
  
//     if(!data) {
//       throw new Error("Post not found")
//     }
  
//     return data
//   }
 
// export const createPost =  async ({
//     title,
//     lang, 
//     featured,
//     summary,
//     article_text,
//     post_image,
//     tag,
//     author_id, 
// }) => {
//     const now = new Date()
//     const slug = slugify(title)
//     const { data, error } = await supabase
//     .from(table)
//     .insert({
//        title,
//        slug,
//        lang, 
//        featured,
//        summary,
//        article_text,
//        published_date: now,
//        post_image,
//        tag_id: tag,
//        author_id,
//        published: false
// })

//     if(error) {
//         throw new Error(error.message)
//     }

//     return data
// }
