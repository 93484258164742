import React from 'react'

// packages
import moment from 'moment'

// mui
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'

// custom components
import BlogHeader from '../PostPreview/BlogHeader'
import PostContent  from '../PostPreview/PostContent'

export default function PostPage({
  post,
  site,
  recentPosts,
  tags,
}) {
    
      const date = post.published_date ? moment(post.published_date).format('MMMM Do YYYY, h:mm A')  : ''
      const title = post.title ? post.title : ''
      const authorName = post.author ? post.author.name  : ''
      const authorId = post.author ? post.author.id  : ''
      const summary = post.summary ? post.summary : ''
      const tag = post.tags ? post.tags.tag_name : ''
      const post_image = post.post_image ? post.post_image : ''
      const siteName = site ? site.site_name : ''
      const siteDescription = site ? site.description : ''
      const siteTwitter = site ? site.twitter : ''
      const siteFacebook = site ? site.facebook : ''
      const adminEmail = site ? site.admin_email : ''
      const tagColor = post.tags ? post.tags.tag_color : ''
      const post_tags = post.post_tags
      const blocks = post.blocks ? post.blocks : ''

      return  <Container maxWidth="lg">
                <BlogHeader 
                  siteName={siteName} 
                  tags={tags}
                />
                <Divider sx={{mt: 1, mb: 2}} /> 
                <PostContent 
                    date={date}
                    title={title}
                    authorName={authorName}
                    authorId={authorId}
                    summary={summary}
                    tag={tag}
                    tagColor={tagColor}
                    post_image={post_image}
                    siteDescription={siteDescription}
                    siteTwitter={siteTwitter}
                    siteFacebook={siteFacebook}
                    adminEmail={adminEmail}
                    recentPosts={recentPosts}
                    post_tags={post_tags}
                    blocks={blocks}
                />
              </Container>
}