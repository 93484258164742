import React from "react";

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { langSwitch } from "../../utils";

const TagDropDown = ({
    tag,
    handleTag,
    tags,
}) => 
    <FormControl  fullWidth size="small"  sx={{minWidth: 150}}>
        <InputLabel >
            Tag *
        </InputLabel>
        <Select
            required={true}
            label='Tag'
            value={tag}
            onChange={handleTag}
            sx={{backgroundColor: 'white'}}
        >
        {tags.map(t => 
            <MenuItem key={t.id} value={t.id}>
                 {t.language ? langSwitch(t.tag_name) : t.tag_name}
            </MenuItem>
        )}
        </Select>
    </FormControl>

export default TagDropDown