import * as React from 'react';
import Grid from '@mui/material/Grid';

import FeaturedPost from './FeaturedPost';

const FeaturedPosts = ({
  otherFeatures
}) =>
      <Grid container spacing={4}>
        {otherFeatures.map((post) => (
          <FeaturedPost key={post.title} {...post} />
        ))}
      </Grid>

export default FeaturedPosts