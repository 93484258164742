import React from 'react'

import { 
  useQuery,
} from "react-query"

import {
  allStats, 
} from '../queries/statsQueries'

import StatsPage from '../components/Pages/StatsPage'

export default function Stats(){

  const title = 'Statistics'

  const { 
    isLoading: isLoadingStats, 
    // isError, 
    // error, 
    data,
   } = useQuery('stats', () => allStats())

   const counts = !isLoadingStats ?  data.counts : []
   const scrapes = !isLoadingStats ?  data.stats : []

  return  <StatsPage  
            title={title}
            counts={counts}
            scrapes={scrapes}
            loading={isLoadingStats} 
          />
  }
