import React from "react";

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from "@mui/material/Typography"
import SearchIcon from '@mui/icons-material/Search'
import red from '@mui/material/colors/red'

import SnackAlert from '../Dialogs/SnackAlert'
import LoadingIconButton from '../Buttons/LoadingIconButton'
import PostListItem from '../ListItems/PostListItem'
import BlogInput from '../Inputs/BlogInput'

import { Divider } from "@mui/material"

const NoResults = () => <Typography color={red['A700']}>No results</Typography>

export default function SearchPage({ 
    title,
    text,
    loading,
    handleTitle,
    handleText,
    searchPosts,
    searchTitle,
    errorText,
    snack,
    msgType,
    alertMsg,
    handleClose,
    searchResults,
 }){
    return(
      <main >
            <Grid container  
                  alignItems="center" 
                  spacing={2} 
                  justifyContent='space-between'
            >
              <Grid item xs={12} lg={10}> 
                <BlogInput 
                  title={title}
                  handleTitle={handleTitle}
                  errorText={errorText}
                  label='Title'
                />
              </Grid>
              <Grid item  >
                <LoadingIconButton
                  size='large'
                  onClick={searchTitle}
                  title='Search Title'
                  loading={loading}
                  icon={<SearchIcon />}
                /> 
              </Grid>
              <Grid item xs={12} lg={10}> 
                <BlogInput 
                  title={text}
                  handleTitle={handleText}
                  errorText={errorText}
                  label='Search Text'
                />
              </Grid>
              <Grid item  >
                <LoadingIconButton
                  size='large'
                  onClick={searchPosts}
                  title='Search Text'
                  loading={loading}
                  icon={<SearchIcon />}
                /> 
              </Grid>
            </Grid>
            <Card sx={{p:3, mt: 1}}>
              <Typography variant="h5" sx={{color:'#6415ff'}}>
                Search Results
              </Typography>
              <Divider sx={{mt:1, mb:1}} />
              {searchResults.length>0 ? 
                <React.Fragment>
                  {searchResults.map(r => 
                    <PostListItem {...r} key={r.id} />
                  )}
                </React.Fragment>
                :
                <NoResults />
                }
            </Card>
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          /> 
        </main>
    )
}