import React from 'react'

// mui components
import Grid from '@mui/material/Grid'

// custom components
import SnackAlert from '../Dialogs/SnackAlert'
import UpdateSite from '../Admin/UpdateSite'
import ManageUsers from '../Admin/ManageUsers'
import ManageAuthors from '../Admin/ManageAuthors'

export default function AdminPage({
  msgType,
  alertMsg,
  snack,
  loading,
  siteName1,
  adminEmail1,
  description1,
  twitter,
  facebook,
  handleClose,
  handleSiteName,
  handleAdminEmail,
  handleDescription,
  handleFacebook,
  handleTwitter,
  updateSite,
  newMembers,
  existingMembers,
  updateRole,
  createAuthor,
  updateAuthor,
  authorName,
  authorEmail,
  authorTwitter,
  activeAuthors,
  handleAuthorName,
  handleAuthorEmail,
  handleAuthorTwitter,
  authorloading,
  openModal,
  authorLanguage,
  handleAuthorLanguage,
}){
    const handleUpdate = (update) => updateRole(update)
    return(
      <Grid container spacing={2}>
            <Grid item xs={12} lg={6}> 
              <UpdateSite
                siteName1={siteName1}
                description1={description1}
                adminEmail1={adminEmail1}
                twitter={twitter}
                facebook={facebook}
                handleSiteName={handleSiteName}
                handleAdminEmail={handleAdminEmail}
                handleDescription={handleDescription}
                handleTwitter={handleTwitter}
                handleFacebook={handleFacebook}
                updateSite={updateSite}
                loading={loading}
              />
            </Grid> 
           
            <Grid item xs={12} lg={6}> 
            <ManageUsers 
                newMembers={newMembers}
                existingMembers={existingMembers}
                handleUpdate={handleUpdate}
              />
            </Grid> 
             <Grid item xs={12} > 
             <ManageAuthors
                createAuthor={createAuthor}
                updateAuthor={updateAuthor}
                authorName={authorName}
                authorEmail={authorEmail}
                authorTwitter={authorTwitter}
                activeAuthors={activeAuthors}
                handleAuthorName={handleAuthorName}
                handleAuthorEmail={handleAuthorEmail}
                handleAuthorTwitter={handleAuthorTwitter}
                authorloading={authorloading}
                openModal={openModal}
                authorLanguage={authorLanguage}
                handleAuthorLanguage={handleAuthorLanguage}
              />
            
            </Grid> 
            <SnackAlert 
              snack={snack}
              handleClose={handleClose}
              msgType={msgType}
              alertMsg={alertMsg}
            />
          </Grid>
  )
}