import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Divider } from '@mui/material';

const Copyright = () =>
    <Typography variant="body2"  align="center" >
      {'Copyright © '}
      <Link color="inherit" href="https://langalearn.com/">
        Langa Learn
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>

const Footer = () => 
    <Container >
      <Box 
        component="footer" 
        sx={{ 
            mt: 2
        }}
      >
       <Divider  sx={{ 
            mt: 3,
            mb: 3
        }} /> 
        <Copyright />
        <Box 
            component="footer" 
            sx={{ 
                mb: 2
            }}
          >
         </Box>
    </Box>
    </Container>

export default Footer