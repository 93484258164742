import React from 'react'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { Button } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'

export default function ServiceRequestListItem({
    id,
    issue,
    email,
    type,
    process,
    resolved,
    resolve_date,
    created_at,
    processRequest,
    resolveRequest,
}){
    const date = moment(created_at).format('MMMM Do YYYY, h:mm a')
    const resolvedate = moment(resolve_date).format('MMMM Do YYYY, h:mm a')

    const resolveR = () => resolveRequest(id)
    const processR = () => processRequest(id)
    return  <Grid item xs={12}>
                <Typography >
                    {/* <Link href={`/user/update_post/${id}`}  style={{color: '#6415ff'}}> */}
                     Issue: {issue}
                    {/* </Link> */}
                </Typography>
                <Grid container 
                      spacing={1} 
                      alignItems='center' 
                      justifyContent='space-between'
                >
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            {date} by <a href={`mailto:${email}`} target="_blank" rel="noreferrer" >{email}</a>
                        </Typography> 
                    </Grid>
                    <Grid item>
                        <Chip label={type} size='small' sx={{backgroundColor: type==='Technical' ? 'blue' : 'orange' , color:'white'}} />
                    </Grid>
                    {resolved &&
                        <Grid item>
                            <Chip 
                                label={resolvedate} 
                                size='small' 
                                color='success' 
                                icon={<DoneIcon />} 
                            />
                        </Grid>
                    }
                    {process==null  &&
                        <Button 
                            size='small' 
                            variant='outlined'
                            onClick={processR}
                        >
                            Process
                        </Button>
                    }
                    {process!==null  && resolved===false &&
                        <Button 
                            size='small' 
                            color='success'
                            variant='outlined'
                            onClick={resolveR}
                        >
                            Resolve
                        </Button>
                    }
                </Grid>
            </Grid>
}