import  React, { useState} from 'react'

// packages
import { useHistory } from "react-router-dom"

// helpers
import { deployCF } from "../../utils"

// DATA

// client
import { supabase } from '../../supabase'

import {
    useQuery, 
} from "react-query"

// queries
import {
    getMember
} from '../../queries/memberQueries'


// UI
// mui
import ListAltIcon from '@mui/icons-material/ListAlt'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AnalyticsIcon from '@mui/icons-material/Analytics'

// custom components
import ResponsiveAppBarComponents from './ResponsiveAppBarComponent'

const siteTitle =  'Langa Learn'

const buttons = [
    {
        title: 'Posts',
        href:  '/user/posts',
        icon: <ListAltIcon />
    },
    {
        title: 'Support Desk',
        href:  '/user/support_desk',
        icon: <ListAltIcon />
    },
    // {
    //     title: 'Search',
    //     href:  '/user/search_posts',
    //     icon: <SearchIcon />
    // },
    
    {
        title: 'Profile',
        href:  '/user/profile',
        icon: <AccountCircle />
    },
    {
        title: 'Analytics',
        href:  '/user/analytics',
        icon: <AnalyticsIcon />
    }
]

const ResponsiveAppBar = () => {

    let history = useHistory()

    // data
    const { isLoading, data: user } = useQuery('member', () => getMember())

    const role = !isLoading && user ? user.role : ''

    const publisher = role==='admin' || role==='editor' ?  true : false

    // state
    const [ anchorElNav, setAnchorElNav] = useState(null)
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)

    const handleClose = () => setSnack(false)
    const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget)
    const handleCloseNavMenu = () => setAnchorElNav(null)

    const showSnack = (type, message) => {
        setSnack(true)
        setMsgType(type)
        setAlertMsg(message)
    }

    // functions
    const publishPosts = async () => {
      try {
        await deployCF()
      } catch(error){
        showSnack('error', 'Publising error!')
      }
    }

    const logout = async () => {
        const { error } = await supabase.auth.signOut()
        if(error){
        showSnack('error', 'Logout error!')
        return
        }
        history.push({pathname: `/`})
    }

    return <ResponsiveAppBarComponents
                buttons={buttons}
                siteTitle={siteTitle}
                publisher={publisher}
                anchorElNav={anchorElNav}
                alertMsg={alertMsg}
                msgType={msgType}
                snack={snack}
                handleClose={handleClose}
                handleOpenNavMenu={handleOpenNavMenu}
                handleCloseNavMenu={handleCloseNavMenu}
                publishPosts={publishPosts}
                logout={logout}
                role={role}
            />
}

export default ResponsiveAppBar
