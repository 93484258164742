import React from 'react'
// import { 
//   useQuery,
// } from "react-query"

// import {
//   allStats, 
// } from '../queries/statsQueries'

import AnalyticsPage from '../components/Pages/AnalyticsPage'

export default function Stats(){

  // const { 
  //   isLoading: isLoadingStats, 
  //   // isError, 
  //   // error, 
  //   data,
  //  } = useQuery('stats', () => allStats())

  //  const counts = !isLoadingStats ?  data.counts : []
  //  const scrapes = !isLoadingStats ?  data.stats : []

  return  <AnalyticsPage  
          
           
          />
  }
