import React  from 'react'
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0
    }
  }
})

const container = document.getElementById('root')
const root = createRoot(container); 

root.render(
         <QueryClientProvider client={queryClient}>
            <Router>
              <Route path="/" component={App} />
            </Router>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
     )

serviceWorker.unregister()
