import React from 'react'

import { Button } from '@mui/material'

const RoleButton = ({
    updateRole, 
    role,
    userRole,
    id,
  }) => {
    const handleUpdate = () => updateRole({role, id})
    return <Button 
              onClick={role!==userRole ? handleUpdate : ()=>{}} 
              color={role===userRole ? 'primary' : 'basic'}
            >
            {role}
          </Button>
  }
  
export default RoleButton