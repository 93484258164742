import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material';
import { queryClient } from "../..";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {TextField} from '@mui/material';
import red from '@mui/material/colors/red'

import LangStatHeader from '../Stats/LangStatHeader'

import AddFeedCard from '../Cards/AddFeedCard'

import { 
  useMutation, 
} from "react-query"

import {
  updateFeed,
  deleteFeed,
} from '../../queries/statsQueries'

import SnackAlert from '../Dialogs/SnackAlert'


const FeedItem = ({
  feed,
  id,
  art_count,
  country
}) => {
  const [ feed1, setFeed1] = useState('')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ msgType, setMsgType ] = useState('info')
  const [ snack, setSnack ] = useState(false)
  
  const handleClose = () => setSnack(false)

  const handleFeed = event => setFeed1(event.target.value)


  const updateFeedMutation = useMutation(() => updateFeed({
    feed: feed1, 
    id
  }), {
    onSuccess: () => {
      queryClient.invalidateQueries('feeds')
      showSnack('success', 'Feed updated!')
    },
    onError: (error) => {
       showSnack('error', error.message)
    },
  })

  const deleteFeedMutation = useMutation(() => deleteFeed(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries('feeds')
      showSnack('success', 'Feed deleted!')
    },
    onError: (error) => {
       showSnack('error', error.message)
    },
  })

  const showSnack = (type, message) => {
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const updateFeed1 = () => updateFeedMutation.mutate()
  const deleteFeed1 = () => deleteFeedMutation.mutate()

  useEffect(() => {
    setFeed1(feed)
  },[feed])

  const country1 = country && country.length>0 ? country : 'Unknown'
  return <Grid container 
              spacing={3} 
              justifyContent='left' 
              alignItems='center' 
          > 
        <Grid item  >
          <TextField
            variant="outlined"
            required
            sx={{
              width:500
            }}
            label='Feed'
            onChange={handleFeed}
            value={feed1}
          />
        </Grid>
        <Grid item xs={1} >
          <Typography textAlign='right'>
            {art_count}
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography textAlign='right'>
            {country1}
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={updateFeed1} variant='contained'  sx={{
            color:'white',
            backgroundColor: '#6415ff',
            '&:hover': {
              backgroundColor: '#4719a3',
            },
          }}> 
            Update 
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={deleteFeed1} variant='contained'  
            sx={{ 
              backgroundColor: red['A400'],
              '&:hover': {
                  backgroundColor: red['A700'],
              },
            }}> 
            Delete
          </Button>
        </Grid>
        <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          /> 
        </Grid>
}

export default function LangRssPage({
  lang,
  rssFeeds,
}){
  const count = rssFeeds.length
  return (
      <main >  
        <LangStatHeader lang={lang} counts={count} />
        <AddFeedCard lang={lang} />
        <Typography variant='h6' sx={{m:2}}>
          Rss Feeds
        </Typography>
        <Grid container spacing={2} sx={{m:1}}>
          {rssFeeds.map(f => 
          <Grid key={f.id} item xs={12}>
            <FeedItem {...f} />
            </Grid>
          )}
        </Grid>
      </main>
    )
  }
