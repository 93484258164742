import React, {useEffect, useState} from 'react'

// DATA 

// clients 
import {queryClient} from '../index'
import {
  useQuery, 
  useMutation
} from "react-query"

// queries
import {
  updateSite,
  getSite,
} from '../queries/siteQueries'

import {
  updateRole,
  getNewMembers, 
  getExistingMembers,
} from '../queries/memberQueries'

import {
  createAuthor,
  updateAuthor,
  getAuthor,
  getAuthors,
} from '../queries/authorQueries'

// UI

// mui components
import CircularProgress from '@mui/material/CircularProgress';

// custom components
import AdminPage from '../components/Pages/AdminPage'

export default function Profile(){

  // data

  // fetch

  const { isLoadingSite, data: site } = useQuery('site', () => getSite())

  const { isLoadingNew, data: newMembers } = useQuery('new_members', () => getNewMembers())

  const { isLoadingExist, data: existingMembers } = useQuery('existing_members', () => getExistingMembers())

  const { isLoadingAuthor, data: activeAuthors } = useQuery('active_authors', () => getAuthors(true))


  const existingMembers1 = !isLoadingExist && existingMembers ? existingMembers : []
  const newMembers1 = !isLoadingNew  && newMembers ? newMembers : []

  // change
  const updateAuthorMutation = useMutation((update) => updateAuthor(update), {
    onSuccess: () => {
      queryClient.invalidateQueries('active_authors')
      showSnack('success', 'Updated author!')
    },
    onError: (error) => {
      showSnack('error', 'Error updating author!')
    },
    onSettled: () => {
      setAuthorLoading(false)
    }
  })

  const createAuthorMutation = useMutation(() => createAuthor({
    name: authorName,
    email: authorEmail,
    twitter: authorTwitter,
    language: authorLanguage,
  }), {
    onSuccess: () => {
      setauthorEmail('')
      setauthorName('')
      setauthorTwitter('')
      setauthorLanguage('')
      queryClient.invalidateQueries('active_authors')
      showSnack('success', 'Updated author!')
    },
    onError: (error) => {
      showSnack('error', 'Error updating author!')
    },
    onSettled: () => {
      setAuthorLoading(false)
    }
  })


  const updateRoleMutation = useMutation((update) => updateRole(update), {
    onSuccess: () => {
      queryClient.invalidateQueries('new_members')
      queryClient.invalidateQueries('existing_members')
      showSnack('success', 'Updated role!')
    },
    onError: (error) => {
      showSnack('error', 'Error updating role!')
    },
  })

  const updateSiteMutation = useMutation((update) => updateSite(update), {
    onSuccess: (data) => {
      queryClient.setQueryData('site', data[0])
      showSnack('success', 'Updated site!')
    },
    onError: () => {
      showSnack('error', 'Error updating site!')
    },
  })

  // state
  const [ authorName, setauthorName ] = useState('')
  const [ authorEmail, setauthorEmail ] = useState('')
  const [ authorTwitter, setauthorTwitter ] = useState('')
  const [ authorLanguage, setauthorLanguage ] = useState('')
  const [ siteName1, setSiteName ] = useState('')
  const [ adminEmail1, setAdminEmail ] = useState('')
  const [ description1, setDescription ] = useState('')
  const [ twitter1, setTwitter ] = useState('')
  const [ facebook1, setFacebook ] = useState('')
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ authorloading, setAuthorLoading ] = useState(false)

  const handleSiteName = event => setSiteName(event.target.value)
  const handleDescription = event => setDescription(event.target.value)
  const handleAdminEmail = event => setAdminEmail(event.target.value)
  const handleTwitter = event => setTwitter(event.target.value)
  const handleFacebook = event => setFacebook(event.target.value)

  const handleAuthorName = event => setauthorName(event.target.value)
  const handleAuthorEmail = event => setauthorEmail(event.target.value)
  const handleAuthorTwitter = event => setauthorTwitter(event.target.value)
  const handleAuthorLanguage = event => setauthorLanguage(event.target.value)


  const openModal = () => {console.log('openModal')}

  const handleClose = () => setSnack(false)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const createAuthor1 = () => {
    setAuthorLoading(true)
    createAuthorMutation.mutate()
  }

  const updateAuthor1 = (id) => {
    setAuthorLoading(true)
    updateAuthorMutation.mutate({
    id,
    name: authorName, 
    email: authorEmail,
    twitter: authorTwitter,
  })}

  const deactivateAuthor = (id) => updateAuthorMutation.mutate({
    id,
    active: false,
  })
 
  // functions
  const updateSite1 = async () => {
    const id = site.id
    updateSiteMutation.mutate({
      id,
      site_name: siteName1, 
      admin_email: adminEmail1,
      description: description1, 
      facebook: facebook1, 
      twitter: twitter1,
    })
  }

  const updateMemberRole = (update) =>  updateRoleMutation.mutate(update)

  useEffect(() => {
    if(site){
      setAdminEmail(site.admin_email)
      setSiteName(site.site_name)
      setDescription(site.description)
      setFacebook(site.facebook)
      setTwitter(site.twitter)

    }
  }, [site])
 
  return isLoadingSite || isLoadingExist || isLoadingNew ? 
          <CircularProgress  />
          : 
          <AdminPage 
            msgType={msgType}
            alertMsg={alertMsg}
            snack={snack}
            loading={loading}
            siteName1={siteName1}
            adminEmail1={adminEmail1}
            description1={description1}
            twitter={twitter1}
            facebook={facebook1}
            handleClose={handleClose}
            handleSiteName={handleSiteName}
            handleAdminEmail={handleAdminEmail}
            handleDescription={handleDescription}
            handleFacebook={handleFacebook}
            handleTwitter={handleTwitter}
            updateSite={updateSite1}
            newMembers={newMembers1}
            existingMembers={existingMembers1}
            updateRole={updateMemberRole}
            createAuthor={createAuthor1}
            updateAuthor={updateAuthor1}
            authorName={authorName}
            authorEmail={authorEmail}
            authorTwitter={authorTwitter}
            activeAuthors={activeAuthors}
            handleAuthorName={handleAuthorName}
            handleAuthorEmail={handleAuthorEmail}
            handleAuthorTwitter={handleAuthorTwitter}
            authorloading={authorloading} 
            openModal={openModal}
            authorLanguage={authorLanguage}
            handleAuthorLanguage={handleAuthorLanguage}
          />
}