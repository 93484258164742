import React from 'react'

// mui
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

const Contact = ({
    adminEmail
}) =>   <>
        <Grid item>
            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                Contact
            </Typography>
        </Grid>
        <Grid item>
            <Link 
                color='#6415ff'
                target="_blank"
                href={`mailto: ${adminEmail}`}
            >
                {adminEmail}
            </Link>
        </Grid>
        </>

export default Contact