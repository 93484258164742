import React from 'react';
import Link from '@mui/material/Link'
import moment from 'moment'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const MainItem = ({
  title,
  published_date,
  summary,
}) => {
  return <Grid item xs={12} >
          <Typography variant="h6" color='#6415ff'> 
            <Link href='' >
              {title}
            </Link>
          </Typography>
          <Typography variant="caption">
            {moment(published_date).format('MMMM Do, YYYY')} 
          </Typography>
          <Typography variant="body1" xs={12} color='textSecondary' sx={{ display: { xs: 'none', md: 'block' } }}>
            {summary}
          </Typography>
        </Grid>
}

export default function Main({
    posts,
}) {
  
  const title = 'Recent Articles'
  const nonFeaturedpost = posts.filter(p => p.featured==false)

  return (
    <Grid
      item
      xs={12}
      md={8}
    >
      <Typography variant="h5" gutterBottom >
        {title}
      </Typography>
      <Divider sx={{ mb:2}} />
      <Grid container  spacing={3}>
      {nonFeaturedpost.map((p) => 
        <MainItem {...p} key={p.title} />
      )}
      </Grid>
    </Grid>
  );
}
