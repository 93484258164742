import React from 'react'

// mui
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

// custom components
import SocialList from '../PostPreview/SocialList'
import AboutCard from '../PostPreview/AboutCard'
import Contact from '../PostPreview/Contact'
import AffiliateLinks from './AffiliateLinks'

const Sidebar = ({
    siteDescription,
    siteTwitter,
    siteFacebook,
    adminEmail,
}) => {

    return  <Grid item xs={12} md={4}>
                <AboutCard 
                    siteDescription={siteDescription} 
                />
                 <Grid>
                    <Divider sx={{mt:3}} />
                </Grid>
                <Grid>
                    <Divider sx={{mt:3, mb: 3}} />
                </Grid>
                <Grid>
                   <AffiliateLinks />
                </Grid>
                <SocialList  
                    siteTwitter={siteTwitter}
                    siteFacebook={siteFacebook} 
                />
                <Contact 
                    adminEmail={adminEmail} 
                />
               
               
            </Grid>
}

export default Sidebar