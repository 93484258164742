import React from 'react'

import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

export default function SnackAlert({
    snack, 
    handleClose, 
    msgType, 
    alertMsg,
}){
        return <Snackbar 
                open={snack} 
                autoHideDuration={msgType==='error' ? 6000 : 1000} 
                onClose={handleClose}
                anchorOrigin={{ 
                  vertical: 'top', 
                  horizontal: 'right'
                }}
              >
                <Alert 
                  elevation={6} 
                  onClose={handleClose} 
                  severity={msgType} 
                >
                  {alertMsg}
                </Alert>
              </Snackbar>
}