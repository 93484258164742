import {supabase} from "../supabase"

const table = 'authors'

export const createAuthor =  async ({
    email,
    name,
    twitter,
    language,
}) => {
 
    const { data, error } = await supabase
        .from(table)
        .insert({
            email,
            name,
            twitter,
            language,
        })

    if(error)  throw new Error(error.message)

    return data
}

export const updateAuthor = async update => {
    const { data, error } = await supabase
        .from(table)
        .update(update)
        .eq('id', update.id)

    if(error) throw new Error(error.message)

    return data
}

export const getAuthor = async (id) => {
    const { data, error } = await supabase
      .from(table)
      .select('email, name, twitter, language, active')
      .eq('id', id)
      .single()

    if(error)  throw new Error(error.message)
  
    if(!data)  throw new Error("Member not found")
  
    return data
  }

export const getAuthors = async (active) => {
  const { data, error } = await supabase
    .from(table)
    .select('*')
    .eq('active', active)
    .order('name')

    if(error)  throw new Error(error.message)

    return data
}
