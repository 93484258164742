import React from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'

import RoleButton from '../Buttons/RoleButton'

const roles = ['writer', 'editor', 'admin']

const MemberItem = ({
    name,
    email,
    role,
    updateRole,
    id,
  }) => <>
          <Grid item  xs={12} >
            <Typography>
              {email}  {name.length ? ` - ${name}` : ''}
            </Typography>
           
          </Grid>
          <Grid item>
            <Grid container>
              {roles.map(r => 
              <Grid item key={r}>
                  <RoleButton 
                    role={r}
                    updateRole={updateRole}
                    userRole={role}
                    id={id}
                  />
                  </Grid>
                )}
                {role!=='admin'
                  && <Grid item>
                      <Button 
                        onClick={() => updateRole({role: 'deactivated', id})} 
                        color='error'
                      >
                        Deactivate
                      </Button>
                    </Grid>
                  }
            </Grid>
          </Grid>
         
        </>

export default MemberItem