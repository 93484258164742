import React from 'react'

// mui
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'

// custom components
import ServiceRequestListItem from '../Service/ServiceRequestListItem'

export default function ServiceRequestList({
    requests,
    title,
    processRequest,
    resolveRequest,
}) {
  return (
    <Card style={{height:'100%'}}>
      <CardContent>
        <Grid container  spacing={3}>
          <Grid item xs={12} >
            <Typography variant='h6' >
              {title}
            </Typography>
            <Divider />
          </Grid>
          {requests && requests.map((p) => 
            <ServiceRequestListItem 
              {...p} 
              key={p.id} 
              processRequest={processRequest}
              resolveRequest={resolveRequest}
            />
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
