import React, {useState} from 'react'
import { queryClient } from "../.."

import { 
    useMutation, 
} from "react-query"
  
import {
  createTag,
} from '../../queries/blogQueries'

import {tabColors} from '../Inputs/ColorDropDown'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { Grid } from '@mui/material'

import ColorDropDown from '../Inputs/ColorDropDown'
import BlogInput from '../Inputs/BlogInput'
import SnackAlert from '../Dialogs/SnackAlert'

export default function AddTagDialog({
    errorText,
    tagOpen,
    toggleTagOpen,
    author_id,
}) {

    const [ tagName, setTagName ] = useState('')
    const [ tagColor, setTagColor ] = useState(tabColors[0])
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)

    const handleTagColor = event => setTagColor(event.target.value)
    const handleTagName = event => setTagName(event.target.value)
    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
        setSnack(true)
        setMsgType(type)
        setAlertMsg(message)
      }

    const addTagMutation = useMutation(() => createTag({
        tagName,
        tagColor,
        author_id
      }), {
        onSuccess: () => {
          queryClient.invalidateQueries('tags')
          setTagName('')
          showSnack('success', 'Success! New tag added!')
        },
        onError: (error) => {
          showSnack('error', 'Error adding new tag!')
        },
      })
    
    const newTag = async () => addTagMutation.mutate()

    return (
      <Dialog open={tagOpen} onClose={toggleTagOpen}>
        <DialogTitle>Add Tag</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Choose a tag name and color. 
            </DialogContentText>
        </DialogContent>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item  >
                    <BlogInput 
                        errorText={errorText}
                        title={tagName}
                        handleTitle={handleTagName}
                        label="New Tag Name" 
                    />
                </Grid>
                <Grid item  >
                    <ColorDropDown
                        tagColor={tagColor}
                        handleTagColor={handleTagColor}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={toggleTagOpen}>
                Cancel
            </Button>
            <Button onClick={newTag}>
                Add Tag
            </Button>
        </DialogActions>
        <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          /> 
      </Dialog>
  )
}
