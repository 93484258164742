import {supabase} from "../supabase"

const table = 'tags'

export const getTags = async () => {
    const { data, error } = await supabase
        .from(table)
        .select(`
            id,
            tag_name,
            tag_color, 
            language
            `)
        .order('tag_name',{ascending: true})

    if(error) throw new Error(error.message)

    if(!data) throw new Error("Cluster not found")

    return data
}

export const createTag =  async ({
    tagName,
    tagColor,
    author_id
}) => {
    const { data, error } = await supabase
    .from(table)
    .insert({
        tag_name: tagName,
        tag_color: tagColor,
        author_id,
})

    if(error) throw new Error(error.message)

    return data
}

export const createPostTags = async tagids => {
    const { error } = await supabase
    .from('post_tags')
    .insert(tagids)

    if(error) throw new Error(error.message)

    return 
}

export const deletePostTag = async ({
    tag_id, 
    post_id,
}) => {
    const {  error } = await supabase
        .from('post_tags')
        .delete()
        .eq('tag_id', tag_id)
        .eq('post_id', post_id)

    if(error) throw new Error(error.message)

    return post_id
}