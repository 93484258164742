import React from 'react'

import LangStatHeader from '../Stats/LangStatHeader'
import DayCountChart from '../Stats/DayCountChart'
import MonthlyCountChart from '../Stats/MonthlyCountChart'
import RecentStats from '../Stats/RecentStats'
import AddFeedCard from '../Cards/AddFeedCard'

export default function StatsPage({
  lang,
  counts,
  scrapes,
  sequences,
  monthlyArtsCount,
}){
 
  return (
      <main >  
        <LangStatHeader lang={lang} counts={counts} />
        <AddFeedCard lang={lang} />
        {scrapes &&
           <RecentStats scrapes={scrapes}  />
        }  
       
        <div >
        {sequences && sequences.length>0 &&
          <DayCountChart 
            sequences={sequences} 
          />
        } 
        </div>
       
        <div >
        {monthlyArtsCount && monthlyArtsCount.length>0 &&
          <MonthlyCountChart
            sequences={monthlyArtsCount} 
          />
        }  
        </div>
      </main>
    )
  }
