import React from "react";

import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'

const BlogInput = ({
    errorText=false,
    title,
    handleTitle,
    label,
    required=false,
    size='small'
}) => 
    <FormControl sx={{ width: '100%'}}>
        <TextField 
            size={size}
            required={required}
            variant="outlined"
            fullWidth
            multiline
            error={errorText}
            sx={{ width:'100%'}}
            label={label}
            value={title} 
            onChange={handleTitle} 
        />
    </FormControl>

export default BlogInput