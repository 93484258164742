import  React from 'react'

// packages
import { useParams } from "react-router-dom"

// DATA

// clients
import { 
  useQuery,
} from "react-query"

// queries
import {
  getPost,
} from '../queries/blogQueries'

import {
  getTags,
} from '../queries/tagsQueries'

import {
  getSite,
} from '../queries/siteQueries'

// UI

// custom componnets
import PostPage from '../components/PostPreview/PostPage'

export default function Post(){

    // DATA

    // fetch
    const {
      id, 
    } = useParams()

    const { isLoading, isError, error, data: post } = useQuery('posts', () => getPost({id}))

    const recentPosts = []
    // const { data: recentPosts } = useQuery('recent_posts', () => getRecentPosts())

    const { data: site } = useQuery('site', () => getSite())

    const { data: tags } = useQuery('tags', () => getTags())

    if(isLoading) return <div>Loading...</div>
    return post ? <PostPage 
                    post={post}
                    isLoading={isLoading} 
                    isError={isError} 
                    error={error}  
                    tags={tags} 
                    site={site}
                    recentPosts={recentPosts}
                  /> : <div />
}