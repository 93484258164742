import React from 'react'


import { Typography, Grid, Card, CardContent, List, ListItem, ListItemText, Divider } from '@mui/material'
export default function StatsPage({
    countries,
    pageViews,
    requests,
    vistors,
    threats,
    curDate,
}){
  let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
  return (
      <Grid container spacing={1}> 
        <Grid item xs={12}>
          <Typography variant='h4' >
            Web Analytics - {curDate}
          </Typography>
        </Grid>
        <Grid item lg={6} md={12}>
          <Card>
            <CardContent >
              <Grid container spacing={3}>
                <Grid item>
                  <Typography variant='h6'>
                    Page Views: <b>{pageViews}</b>
                  </Typography>
                </Grid>
                <Grid item> 
                  <Typography variant='h6'>
                    Visitors: <b>{vistors}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h6'>
                     Requests: <b>{requests}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h6'>
                     Threats: <b>{threats}</b>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent >
              <List >
                {countries.map(c => 
                  <ListItem key={c.clientCountryName}>
                    <ListItemText
                      primary={`${regionNames.of(c.clientCountryName)}: ${c.requests}`}
                    />
                  </ListItem>
                  )}

              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
}
