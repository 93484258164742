import React, {useEffect, useState} from 'react'

// DATA 

// clients and helpers
import {supabase} from '../supabase'
import {queryClient} from '../index'
import {
  useQuery, 
  useMutation
} from "react-query"

// queries
import {
  updateMember,
  getMember
} from '../queries/memberQueries'

// UI

// mui components
import CircularProgress from '@mui/material/CircularProgress';

// custom components
import ProfilePage from '../components/Pages/ProfilePage'

export default function Admin(){

  // DATA

  // fetch
  const { isLoading, data: user } = useQuery('member', () => getMember())

  // change
  const  updateMemberMutation = useMutation((update) => updateMember(update), {
    onSuccess: (data) => {
      queryClient.setQueryData('member', data[0])
      showSnack('success', 'Updated profile!')
    },
    onError: (error) => {
      showSnack('error', 'Error updating profile!')
    },
  })

  // state
  const [ name1, setName ] = useState('')
  const [ email1, setEmail ] = useState('')
  const [ msgType, setMsgType ] = useState('info')
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ snack, setSnack ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const handleName = event => setName(event.target.value)
  const handleEmail = event => setEmail(event.target.value)
  const handleClose = () => setSnack(false)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

 
  // functions
  const updateProfile = async () => {

    if (user.email !== email1){
      const { error } = await supabase.auth.update({email: email1})
      if(error){
        showSnack('error', 'Error updating email!')
        return
      }
    }
    
    updateMemberMutation.mutate({
      name: name1, 
      email: email1,
    })
  }

  useEffect(() => {
    if(user){
      setEmail(user.email)
      setName(user.name)
    }
  }, [user])

  return isLoading && user ? 
          <CircularProgress  />
          : 
          <ProfilePage 
            name1={name1}
            email1={email1}
            msgType={msgType}
            alertMsg={alertMsg}
            snack={snack}
            loading={loading}
            handleClose={handleClose}
            handleName={handleName}
            handleEmail={ handleEmail}
            updateProfile={updateProfile}
          />
}