import React from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material';
import { langSwitch } from '../../utils'

import FlagRound from "../ImageElements/FlagRound"

export default function LangStatHeader({
    lang,
    counts,
}){
    const language = langSwitch(lang)
    return(
        <Card sx={{m:1}}>
            <CardContent>
                <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item>
                    <FlagRound
                        lang={lang}
                        flagSize='small'
                    />
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">         
                            {language} Article Total {counts && counts.toLocaleString(undefined)}
                        </Typography>
                    </Grid>

                    <Grid item>
                       <Button href={`/user/lang_rss/${lang}`} variant='contained'  size='small'  sx={{
                            color:'white',
                            backgroundColor: '#6415ff',
                            '&:hover': {
                                backgroundColor: '#4719a3',
                            },
                        }}>
                            Rss Feeds
                       </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}