import React from 'react'

// mui
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import { IconButton } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

// custom components
import PostListItem from '../ListItems/PostListItem'

export default function EditPostList({
    posts,
    title,
    forPage,
    backPage,
    page,
    postcount,
}) {
  const more = posts.length>=postcount || posts.length<10 
  return (  
    <Card style={{height:'100%'}}>
      <CardContent>
        <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
          <Grid item  >
            <Typography variant='h6' >
              {title}
            </Typography>
          </Grid>
          <Grid item  >
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <IconButton onClick={backPage}  disabled={page===0}>
                  <ChevronLeft />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant='h6' >
                  {page+1}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={forPage} disabled={more}>
                    <ChevronRight />
                  </IconButton>
                </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {posts.map((p) => 
            <PostListItem {...p} key={p.id} />
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
