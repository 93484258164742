import React, {useEffect, useState} from 'react'
import { supabase } from '../supabase'
// UI
import { CircularProgress } from '@mui/material'

// custom components
import BlogPreviewPage from '../components/Pages/BlogPreviewPage'

export default function Blog(){

    const [postList, setpostList] = useState([])
    const [featuredList, setfeaturedList] = useState('')
    const [isLoading, setisLoading] = useState(false)

    const [isError, setisError] = useState('')
    const [error, seterror] = useState(false)
    const [site, setsite] = useState()
    const [tags, settags] = useState([])

    useEffect(() => {
       const postQuery = `
            id,
            title, 
            article_text,
            summary,
            published_date,
            post_image,
            featured,
            post_tags(
            tags(
                id,
                tag_name,
                tag_color,
                language
            )
        )`

        const getPosts = async  () => {
            setisLoading(true)
            const { data: postList, error } = await supabase
                .from('blogs')
                .select(postQuery)
                .eq('published', true)
                .eq('featured', false)
                .order('published_date',{ascending: false})
                .limit(10)

            if(error){
                setisError(true)
                seterror(error.message)
                return
            }
            
            if(postList && postList.length>0){
                setpostList(postList)
                setisLoading(false)
            }
        }

        const getFeaturedPosts = async  () => {
            const { data: featuredList, error } = await supabase
                .from('blogs')
                .select(postQuery)
                .eq('published', true)
                .eq('featured', true)
                .order('published_date',{ascending: false})
                .limit(3)
            
                if(error){
                    setisError(true)
                    seterror(error.message)
                    return
                }
               
                if(featuredList && featuredList.length>0){
                    setfeaturedList(featuredList)
                }
        
        }
       
    
        const getSite = async  () => {
            const { data, error } = await supabase
                .from('site')
                .select(`*`)
            
            if(error){
                setisError(true)
                seterror(error.message)
                return
            }
            
            if(data && data.length>0){
                setsite(data[0])
            }
        
        }

        const getTags = async  () => {
            const { data: tags, error } = await supabase
                .from('tags')
                .select(`
                    id,
                    tag_name,
                    tag_color,
                    language
                `)
                .order('tag_name',{ascending: true})
        
            if(error){
                setisError(true)
                seterror(error.message)
                return
            }
            
            if(tags && tags.length>0){
                settags(tags)
            }
        }

        getSite()
        getFeaturedPosts()
        getPosts()
        getTags()
    },[])


   if(isError) return <div style={{color: 'red'}}>{error}</div>
   if(isLoading) return <CircularProgress />
   if(site!==null) return <BlogPreviewPage 
                            posts={postList} 
                            featuredList={featuredList}
                            isLoading={false} 
                            isError={false} 
                            error={'error'}  
                            site={site} 
                            tags={tags} 
                            />
}