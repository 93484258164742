import React, {useState} from "react"

// DATA

// clients
import { 
  useQuery,
  useMutation,
} from "react-query"

import { queryClient } from ".."


// queries

import {
  getServiceRequests,
  updateServiceRequest,
} from '../queries/serviceDeskQueries'

// UI

// custom components
import ServiceDeskPage from '../components/Pages/ServiceDeskPage'

export default function ServiceDesk(){

  // DATA

  // get
  const { 
    isLoading, 
    // isError, 
    // error, 
    data,
   } = useQuery('service_requests', () => getServiceRequests())

  const requests = !isLoading ?  data: []
  const newRequests = requests.filter(d => !d.process)
  const processedRequests = requests.filter(d => d.process &&  !d.resolved)
  const resolvedRequests = requests.filter(d => d.resolved===true)

  // state
  const [ loading, setLoading ] = useState(false)
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ msgType, setMsgType ] = useState('info')
  const [ snack, setSnack ] = useState(false)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  const updateServiceRequestMutation = useMutation((update) => updateServiceRequest(update), {
    onSuccess: () => {
      queryClient.invalidateQueries('service_requests')
      // setAddTag('')
      // showSnack('success', 'Success! New tag added!')
    },
    onError: (error) => {
      showSnack('error', 'Error updating service request!')
    },
    onSettled: (data)=>{
      setLoading(false)
    }
  })

  const handleClose = () => setSnack(false)

  const processRequest = (id) => updateServiceRequestMutation.mutate({id, process:true})
  
  const resolveRequest = (id) =>{ 
    const now = new Date()
    updateServiceRequestMutation.mutate({
      id, 
      resolved: true,
      resolve_date: now,
    })
  }


  // functions

  return <ServiceDeskPage
          title='Service Requests'
          newRequests={newRequests}
          processedRequests={processedRequests}
          resolvedRequest={resolvedRequests}
          loading={loading}
          snack={snack}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
          processRequest={processRequest}
          resolveRequest={resolveRequest}
        />
}