import React, { useState, useEffect, useCallback, useRef } from "react"

// packages
import { useHistory } from "react-router-dom"

// DATA

// clients
import { queryClient } from ".."

import { 
  useMutation, 
  useQuery,
} from "react-query"

// queries
import {
  getTags, 
  createTag,
  createPost,
} from '../queries/blogQueries'

import {
  getAuthors
} from '../queries/authorQueries'

import {getMember} from '../queries/memberQueries'

// UI

import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'

// custom components
import SnackAlert from '../components/Dialogs/SnackAlert'
import LoadingIconButton from '../components/Buttons/LoadingIconButton'
import BasicIconButton from '../components/Buttons/BasicIconButton'

import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../tools'

// mui
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import ImageIcon from '@mui/icons-material/Image'
import AddIcon from '@mui/icons-material/Add'
import Link from "@mui/material/Link"

import ListIcon from '@mui/icons-material/List';

// custom components
import TagDropDown from '../components/Inputs/TagDropDown'
import LangDropDown from "../components/Inputs/LangDropDown"
import BlogInput from '../components/Inputs/BlogInput'
import AddTagDialog from '../components/Dialogs/AddTagDialog'
import PostTag from '../components/Inputs/PostTag'
import AuthorDropDown from '../components/Inputs/AuthorDropDown'
import { Divider } from "@mui/material"

// custom components

const imageSites = [
  {
      title: 'Unsplash',
      url: 'https://unsplash.com'
  },
  {
      title: 'Pexels',
      url: 'https://www.pexels.com'
  },
  {
      title: 'Pixabay',
      url: 'https://pixabay.com/'
  },
]

export default function AddPost(){

    const ReactEditorJS = createReactEditorJS()

    let history = useHistory()

    // data

    // fetch
    const { 
      isLoading, 
      data: tags1,
    } = useQuery('tags', () => getTags())

    const { 
      isAuthorLoading, 
      data: authors,
    } = useQuery('active_authors', () => getAuthors(true))
    
    const { 
      isLoading: asdf, 
      data: user,
     } = useQuery('profile', () => getMember())

     // const authorName = !asdf && user ? user.name : ''
     const author_id = !asdf && user ? user.id : ''
     const authors1 = !isAuthorLoading && authors ?  authors : []
     const authorNativeLang = !asdf && user ? user.native_lang : ''
     const tags = !isLoading && tags1 ? tags1 : []
     const firstTag = tags.length>0 ? tags[0].id : 1 

     // change
    const addTagMutation = useMutation(() => createTag({
      tabName: addtag,
      author_id
    }), {
      onSuccess: () => {
        queryClient.invalidateQueries('tags')
        setAddTag('')
        showSnack('success', 'Success! New tag added!')
      },
      onError: (error) => {
        showSnack('error', 'Error adding new tag!')
      },
      onSettled: (data)=>{
        setLoading(false)
      }
    })

    const addPostMutation = useMutation(() => createPost({
      title,
      lang,
      featured: false,
      summary,
      post_image,
      tag,
      postTags,
      blocks,
      postAuthor,
    }), {
      onSuccess: (data) => {
        const id = data[0].id
        queryClient.invalidateQueries('posts')
        showSnack('success', 'Success! New post added!')
        history.push(`/user/update_post/${ id}`)
      },
      onError: (error) => {
        showSnack('error', 'Error adding new post! ' + error)
      },
      onSettled: (data)=>{
        setLoading(false)
      }
    })

    const editorCore = useRef(null);
    // state
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)
    const [ title, setTitle ] = useState('')
    const [ addtag, setAddTag ] = useState('')
    const [ summary, setSummary ] = useState('')
    const [ postAuthor, setPostAuthor ] = useState(2)
    const [ lang, setLang ] = useState('en')
    const [ post_image, setPost_image ] = useState('')
    const [ tag, setTag ] = useState(firstTag)
    const [ postTags, setPostTags ] = useState([])
    const [ tagColor, setTagColor ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ openEditor, setOpenEditor] = useState(false)
    const [ tagOpen, setTagOpen ] = useState(false)
    const [ blocks, setBlocks ] = useState()
    const errorText = false
    const handleLang = event => setLang(event.target.value)
    const handleTag = event => setTag(event.target.value)
    const handleaddTag = event => setAddTag(event.target.value)
    const handlePost_image = event => setPost_image(event.target.value)
    const handleSummary = event => setSummary(event.target.value)
    const handleTitle = event => setTitle(event.target.value)
    const handleTagColor = event => setTagColor(event.target.value)
    const handlePostAuthor = event => setPostAuthor(event.target.value)

    const handleEditorModal = () => setOpenEditor(!openEditor)
    const handleClose = () => setSnack(false)
    const toggleTagOpen = () => setTagOpen(!tagOpen)

    const showSnack = (type, message) => {
      setLoading(false)
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    // functions

    const addPost = async () => {
      if(title.length>0 && summary.length>0 && post_image.length>0 && blocks!==null && blocks.blocks.length>1){
        setLoading(true)
        addPostMutation.mutate()
      } else {
        showSnack('error', 'Please add text in the required fields')
      }
    
    }

    const addPostTag = () => {
      if(!postTags.includes(tag)){
        setPostTags([...postTags, tag])
      }
     }

     const deletePostTag = (id) => {
      const newtags = postTags.filter(p => p!==id)
      setPostTags(newtags)
     }

    const handleSave = useCallback(async () => {
      // retrieve data inserted
      const savedData = await editorCore.current.save();
      // save data
      setBlocks(savedData);
    }, [setBlocks]);

    const handleInitialize = useCallback((instance) => {
      // await instance._editorJS.isReady;
      instance._editorJS.isReady
        .then(() => {
          // set reference to editor
          editorCore.current = instance;
        })
     
    }, []);

    const newTag = async () => addTagMutation.mutate()

    const langs = ['en', 'es', 'de', 'it', 'fr', 'pt']

    useEffect(() => {
      if(authorNativeLang.length>0){
        setLang(authorNativeLang)
      }
      if(authors1.length>0){
        setPostAuthor(authors[0].id)
      }
    },[authorNativeLang, authors1])
   
    return (
      <main >
        <Grid container  
              alignItems="center" 
              spacing={2} 
              justifyContent='space-between'
        >   
          <Grid item xs={12}> 
          <Grid container  
                alignItems="center" 
                spacing={2} 
                justifyContent='space-between'
          >
                <Grid item xs={12} md={6} lg={8}> 
                  <FormControl sx={{ width: '100%'}}>
                    <BlogInput 
                      required={true}
                      title={title}
                      handleTitle={handleTitle}
                      label="Title" 
                    />
                  </FormControl>  
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  {authors1.length>0 && 
                    <AuthorDropDown
                      authors={authors1}
                      postAuthor={postAuthor}
                      handlePostAuthor={handlePostAuthor}
                    />}
                </Grid>
                <Grid item xs={12} md={3} lg={2}> 
                  <LangDropDown
                    lang={lang}
                    langs={langs}
                    handleLang={handleLang}
                  />
                </Grid>
                <Grid item xs={12}> 
                  <BlogInput 
                    required={true}
                    title={summary}
                    handleTitle={handleSummary}
                    label="Summary" 
                  />
                </Grid>  
                <Grid item xs={12}> 
                  <Grid container  
                      alignItems="center" 
                      spacing={2} 
                      justifyContent='space-between'
                  >
                    <Grid item >
                      <Grid container  
                        alignItems="center" 
                        spacing={2} 
                        justifyContent='space-between'
                    >
                      {tags.length>0 &&
                        <Grid item  >
                          <TagDropDown 
                            tag={tag}
                            tags={tags}
                            handleTag={handleTag}
                          />
                        </Grid>
                      }
                      <Grid item> 
                        <BasicIconButton 
                          size='small'
                          title='Add Tag'
                          actionFunc={addPostTag}
                          icon={<AddIcon />}  
                          />
                      </Grid>
                      <Grid item> 
                        <BasicIconButton 
                          size='small'
                          title='Tags'
                          actionFunc={toggleTagOpen}
                          icon={<ListIcon />}  
                          />
                      </Grid>
                      {postTags.length>0 && 
                        <Grid item > 
                          <Grid container spacing={2} alignItems='center'>
                            {postTags.map(p =>  
                              <PostTag 
                                key={p} 
                                tags={tags} 
                                tag_id={p} 
                                deletePostTag={deletePostTag} 
                              />
                            )}
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                </Grid> 
                <Grid item  xs={12}> 
                  <Grid container justifyContent='space-between' >
                    <Grid item md={8} xs={12} > 
                      <BlogInput 
                        required={true}
                        title={post_image} 
                        handleTitle={handlePost_image}
                        label="Post Image" 
                      />
                    </Grid>

                    <Grid item >
                      {post_image.length>0 ?
                        <img src={post_image} alt='selected pick' height='80' style={{borderRadius: 5}}/>
                        :
                        <ImageIcon sx={{ fontSize: 36 }}  color='primary'/>
                      }
                    </Grid> 

                    <Grid item xs={12}>
                      <Grid container spacing={1}  >
                        {imageSites.map(s => 
                          <Grid item key={s.title}>
                            <Link href={s.url} target="_blank" color='#6415ff' variant='body2'  >
                              {s.title}
                            </Link>
                          </Grid>
                        )}
                        </Grid>
                      </Grid>
                    </Grid>

                  {/* {post_image.length>0 ?
                    <img src={post_image} alt='selected pick' height='80' style={{borderRadius: 5}}/>
                    :
                    <ImageIcon sx={{ fontSize: 36 }}  color='primary'/>
                  } */}
                </Grid>
                <Grid item  xs={12}> 
                  <Divider />
                </Grid>
                <Grid item xs={12}> 
                  <ReactEditorJS 
                    defaultValue={blocks} 
                    tools={EDITOR_JS_TOOLS} 
                    onChange={handleSave}
                    onInitialize={handleInitialize}
                  />
                </Grid>
                
              </Grid>
          </Grid>
          <Grid item  >
            <LoadingIconButton
              size='large'
              onClick={addPost}
              title='Add Article'
              loading={loading}
              icon={<AddIcon />}
              /> 
          </Grid>
          <Grid item> 
            <BasicIconButton 
              size='large'
              title= 'Full Screen Editor'
              actionFunc={handleEditorModal}
              icon={openEditor ? <CloseFullscreenIcon /> : <OpenInFullIcon />}  
              />
          </Grid>
        </Grid>
        <AddTagDialog  
          handleTagColor={handleTagColor}
          tagColor={tagColor}
          handleaddTag={handleaddTag}
          addTag={addtag}
          errorText=''
          newTag={newTag}
          tagOpen={tagOpen}
          toggleTagOpen={toggleTagOpen}
          author_id={author_id}
        />
        <SnackAlert 
          snack={snack}
          handleClose={handleClose}
          msgType={msgType}
          alertMsg={alertMsg}
        /> 
      </main>
    )
    
   
}