import React, { useState } from "react";
import { queryClient } from "../..";
import { 
    useMutation, 
} from "react-query"

import {
    addFeed,
} from '../../queries/statsQueries'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

import LoadingIconButton   from '../Buttons/LoadingIconButton'
import SnackAlert from '../Dialogs/SnackAlert'
import CountryDropDown from '../DropDowns/CountryDropDown'

export default function AddFeedCard({
    lang,
}){

    const addfeed = 'Add Feed'
    const feedadded = 'Feed added!'

    const [ link, setLink ] = useState('')
    const [ country, setCountry ] = useState('')
    const [ alertMsg, setAlertMsg ] = useState('')
    const [ msgType, setMsgType ] = useState('info')
    const [ snack, setSnack ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const handleLink = event => setLink(event.target.value)

    const handleCountry = event => setCountry(event.target.value)

    const handleClose = () => setSnack(false)

    const showSnack = (type, message) => {
      setSnack(true)
      setMsgType(type)
      setAlertMsg(message)
    }

    const addFeedMutation = useMutation(() => addFeed({
        lang, 
        link, 
        country, 
      }), {
        onSuccess: () => {
            queryClient.invalidateQueries('feeds')
            setLink('')
            setCountry('')
            showSnack('success', feedadded)
        },
        onError: (error) => {
            showSnack('error', error.message)
        },
        onSettled: ()=> {
          setLoading(false)
        }
      })

    const addFeed1 = async () => {
        const validLink = link.startsWith('http')
        if(link.length<5 || !validLink){
            return showSnack('error', 'Enter a valid link!')
        }

        if(country.length<5){
            return  showSnack('error', 'Select a country!')
        }

        setLoading(true)
        addFeedMutation.mutate()
    }

    return(
        <Card style={{margin:10}}>
            <CardContent>
                    <Grid container  alignItems="center"  justifyContent='space-between' >
                        <Grid item md={7}>
                            <TextField
                                style={{ width: '100%'}}
                                label="Feed" 
                                onChange={handleLink}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <CountryDropDown
                                onChange={handleCountry}
                                value={country}
                                label='Country'
                            />
                        </Grid>
                        <Grid item >
                            <LoadingIconButton  
                                onClick={addFeed1} 
                                title={addfeed}
                                size='large'
                                loading={loading}
                                icon={<AddIcon />}
                            />
                        </Grid>
                    </Grid>
                    <SnackAlert 
                        snack={snack}
                        handleClose={handleClose}
                        msgType={msgType}
                        alertMsg={alertMsg}
                    /> 
            </CardContent>
        </Card>
    )
}


