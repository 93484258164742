import React from 'react'

import { Typography, Grid, Card, CardContent } from '@mui/material'
export default function StatsPage(){
  return (
      <Grid container spacing={1}> 
        <Grid item xs={12}>
          <Typography variant='h4' >
            Google Analytics
          </Typography>
        </Grid>
        <Grid item lg={4} md={12}>
          <Card>
            <CardContent >
              <Typography variant='h5' >
                Google Analytics
              </Typography>
            </CardContent>
            <CardContent >
              asdf
            </CardContent>
          </Card>

        </Grid>
        <Grid item lg={4} md={12}>
          <Card>
            <CardContent >
              <Typography variant='h5' >
                Web Analytics
              </Typography>
            </CardContent>
            <CardContent >
              asdf
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} md={12}>
        <Card>
            <CardContent >
              <Typography variant='h5' >
                Google Cloud
              </Typography>
            </CardContent>
            <CardContent >
              asdf
            </CardContent>
          </Card>
        </Grid>
       
      </Grid>
    )
}
