import React, {useMemo} from "react";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

export default function DayCountChart({sequences}){

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Articles Per Day (Last 30 Days)',
      },
    },
  };

  const labels = useMemo(() => sequences.map(m => m.day), [sequences])
  const data1 = useMemo(() => sequences.map(m => m.total), [sequences])

  const data = {
    labels,
    datasets: [
      {
        label: 'Articles Count',
        data: data1,
        backgroundColor: '#6415ff',
      },
    ],
  };


  return <Bar options={options} data={data} />
}