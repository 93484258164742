const url = 'https://cfsb.langalearn.workers.dev/cf_analytics'

export async function fetchCFAPI(date)  {
    let request = new Request(url)
   
    const query = {"date": date}
    let init = {
        method: 'POST',
        body: JSON.stringify(query)
    }
  
    const res = await fetch(request, init)
    const data = await res.json()
    return data
}