import React from "react"

import Grid from '@mui/material/Grid'
import { Typography } from "@mui/material"

import SearchIcon from '@mui/icons-material/Search'

import SnackAlert from '../Dialogs/SnackAlert'
import BasicLinkIconButton from '../Buttons/BasicLinkIconButton'
import ServiceRequestList from '../Service/ServiceRequestList'

export default function ServiceDeskPage({ 
  newRequests,
  processedRequests,
  resolvedRequest,
  snack,
  msgType,
  alertMsg,
  handleClose,
  processRequest,
  resolveRequest,
 }){

    return(

      <main >
            <Grid container  
                  alignItems="center" 
                  spacing={1} 
                  justifyContent='space-between'
            >
               <Grid item > 
                <Typography variant="h5"  color='primary'>
                  Service Requests
                </Typography>
               </Grid>
               
               <Grid item > 
                <Grid container  
                  alignItems="center" 
                  spacing={1} 
                >
                 
                  <Grid item > 
                    <BasicLinkIconButton
                        size="small"
                        title="Search"
                        to='/user/support_search'
                        icon={<SearchIcon />}
                    />
                  </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12}> 
                <Grid container  
                    alignItems="start" 
                    spacing={2} 
                    justifyContent='space-between'
                >
                  <Grid item xs={12} md={4}>
                    <ServiceRequestList
                      requests={newRequests} 
                      title='New'
                      processRequest={processRequest}
                      resolveRequest={resolveRequest}
                    />
                  </Grid>
                  <Grid item  xs={12} md={4}>
                    <ServiceRequestList
                      requests={processedRequests} 
                      title='In Process'
                      processRequest={processRequest}
                      resolveRequest={resolveRequest}
                    />
                  </Grid>
                  <Grid item  xs={12} md={4}>
                    <ServiceRequestList
                      requests={resolvedRequest} 
                      title='Resolved'
                      processRequest={processRequest}
                      resolveRequest={resolveRequest}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          <SnackAlert 
            snack={snack}
            handleClose={handleClose}
            msgType={msgType}
            alertMsg={alertMsg}
          /> 
        </main>
    )
}