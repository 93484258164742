import React from "react";

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { langSwitch } from "../../utils";

const LangDropDown = ({
    lang,
    handleLang,
    langs,
}) => 
    <FormControl  fullWidth size="small"  sx={{minWidth: 100}}>
        <InputLabel >
            Article Language *
        </InputLabel>
        <Select
            required={true}
            label='Article Language'
            value={lang}
            onChange={handleLang}
            sx={{backgroundColor: 'white'}}
        >
        {langs.map(l => 
            <MenuItem key={l} value={l}>
                 {langSwitch(l)}
            </MenuItem>
        )}
        </Select>
    </FormControl>

export default LangDropDown