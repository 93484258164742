import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'

import { Typography, Grid, Card, CardContent } from '@mui/material'
export default function StatsPage(){
  return (
      <Grid container spacing={1}> 
        <Grid item xs={12}>
          <Typography  variant='h4' >
            Analytics
          </Typography>
        </Grid>
        <Grid item lg={4} md={12}>
          <Card>
            <CardContent >
              <Link variant='h5' component={RouterLink} to="/user/google_analytics" >
                Google Analytics
              </Link>
            </CardContent>
            <CardContent >
              asdf
            </CardContent>
          </Card>

        </Grid>
        <Grid item lg={4} md={12}>
          <Card>
            <CardContent >
              <Link variant='h5' component={RouterLink} to="/user/web_analytics"  >
                Web Analytics
              </Link>
            </CardContent>
            <CardContent >
              asdf
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} md={12}>
        <Card>
            <CardContent >
            <Link variant='h5' component={RouterLink} to="/user/google_cloud"  >
                Google Cloud
              </Link>
            </CardContent>
            <CardContent >
              asdf
            </CardContent>
          </Card>
        </Grid>
       
      </Grid>
    )
}
