import React from 'react'

// UI

// mui components
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import EditIcon from '@mui/icons-material/Edit'

// custom components
import LoadingIconButton from '../Buttons/LoadingIconButton'

export default function UpdateSite({
  siteName1,
  description1, 
  adminEmail1,
  twitter, 
  facebook,
  handleSiteName,
  handleAdminEmail,
  handleDescription,
  handleTwitter,
  handleFacebook,
  updateSite,
  loading,
}){

    return(
    <Card>
      <CardContent>
        <Typography variant={'h5'} color='primary'>
          Site
        </Typography>
      </CardContent>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <CardContent>
        <TextField 
          required
          value={siteName1} 
          onChange={handleSiteName} 
          sx={{ width: '100%'}}
          label='Name'
        />
      </CardContent>
      <CardContent>
        <TextField 
          multiline
          required
          value={description1} 
          onChange={handleDescription} 
          sx={{ width: '100%'}}
          label='Site Description'
        />
      </CardContent>
      <CardContent>
        <TextField 
          value={adminEmail1} 
          onChange={handleAdminEmail} 
          sx={{ width: '100%'}}
          label='Admin Email'
          required
        />
      </CardContent>
      <CardContent>
        <TextField 
          value={twitter} 
          onChange={handleTwitter} 
          sx={{ width: '100%'}}
          label='Twitter'
        />
      </CardContent>
      <CardContent>
        <TextField 
          value={facebook} 
          onChange={handleFacebook} 
          sx={{ width: '100%'}}
          label='Facebook'  
        />
      </CardContent>
      <CardContent>
        <LoadingIconButton
          loading={loading}
          icon={<EditIcon />}
          onClick={updateSite} 
          title='Submit'
        />
      </CardContent> 
    </Card>
  )
}