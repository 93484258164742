import red from '@mui/material/colors/red'
import green from '@mui/material/colors/green'
import grey from '@mui/material/colors/grey'

export const purpleTheme = {
    palette: {
      primary: {
        main: '#6415ff',
      },
      secondary: {
        main: green['A400'],
      },
      error: {
        main: red['A400']
      },
      basic: {
        main: grey['600']
      },
      facebook: {
        main: '#4267B2'
      },
      twitter: {
        main: '#1DA1F2'
      }
    },
  }