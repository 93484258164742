import React, {useState} from "react"

// helpers
import { deployCF } from "../utils"

// DATA

// clients
import { 
  useQuery,
} from "react-query"

// queries
import {
  getMember
} from '../queries/memberQueries'

// UI

// custom components
import PostListPage from '../components/Pages/PostListPage'

export default function PostList(){

  // DATA

  // get

  const { isLoading1, data: user } = useQuery('member', () => getMember())

  const role = !isLoading1 && user ? user.role : ''

  const publisher = role==='admin' || role==='editor' ?  true : false

  // state
  const [ loading, setLoading ] = useState(false)
  const [ alertMsg, setAlertMsg ] = useState('')
  const [ msgType, setMsgType ] = useState('info')
  const [ snack, setSnack ] = useState(false)

  const handleClose = () => setSnack(false)

  const showSnack = (type, message) => {
    setLoading(false)
    setSnack(true)
    setMsgType(type)
    setAlertMsg(message)
  }

  // functions
  const publishPosts = async () => {
    setLoading(true)
    try {
      await deployCF()
      showSnack('success', 'Posts published!')
    } catch(error){
      showSnack('error', 'Publising error!')
    }
  }

  return <PostListPage
          title='Posts'
          publishPost={publishPosts}
          loading={loading}
          snack={snack}
          publisher={publisher}
          msgType={msgType}
          alertMsg={alertMsg}
          handleClose={handleClose}
        />
}