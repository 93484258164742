import React, {useEffect, useState} from 'react'
import moment from 'moment'

import {fetchCFAPI} from '../queries/analyticsQueries'

import WebAnalyticsPage from '../components/Pages/WebAnalyticsPage'

export default function WebAnalytics(){

  const [ vistors, setVisitors ] = useState(0)
  const [ pageViews, setPageViews ] = useState(0)
  const [ requests, setRequests ] = useState(0)
  const [ threats, setThreats ] = useState(0)
  const [ countries, setCountries ] = useState([])
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ curDate, setCurDate ] = useState('')

  useEffect(() => {
    const mnow = moment()
    const now =  mnow.format()
    const asdf = mnow.format('MMMM Do YYYY')
    const cur = now.slice(0,10)
    setCurDate(asdf)
    const getWebA = async (date) => {
      try {
        const stats = await fetchCFAPI(date)
        setCountries(stats.sum.countryMap)
        setPageViews(stats.sum.pageViews)
        setRequests(stats.sum.requests)
        setThreats(stats.sum.threats)
        setVisitors(stats.uniq.uniques)
      } catch(error){
        setError(true)
      } finally{
        setLoading(false)
      }
    }
    getWebA(cur)
  },[])

  if(error) return <div>Error</div>
  if(loading) return <div>Loading...</div>

  return  <WebAnalyticsPage  
            countries={countries}
            pageViews={pageViews}
            requests={requests}
            vistors={vistors}
            curDate={curDate}
            threats={threats}
          />
  }
