import React, {useState, useEffect} from "react"
import { Alert, CircularProgress } from "@mui/material"

// DATA

// queries
import {
    getPublishedPosts
} from '../../queries/blogQueries'

// UI

// custom components
import EditPostList  from '../Post/EditPostList'

export default function PublishedPosts(){

    // DATA

    // get
   
    const changePage = (num) => setPage(prevState => prevState +num)

    const forPage = () => changePage(+1)
    const backPage = () => page>0 && changePage(-1)
  
    const [page, setPage] = useState(0)
    const [postcount, setPostCount] = useState(0)
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
  
    useEffect(() => {
        const getPub = async (page) => {
            try {
                const data = await getPublishedPosts(page)
             
                if(data){
                    setPosts(data.data)
                    setPostCount(data.count)
                }
            } catch(error){
                setError(error)
            } finally{
                setLoading(false)
            }
        }
          getPub(page)
    },[page])

    if(loading) return <center><CircularProgress sx={{p:3}} /></center>
    if(error) return <Alert severity="error">Error {error}</Alert>

    return    <EditPostList 
                posts={posts} 
                title='Published'
                forPage={forPage}
                backPage={backPage}
                page={page}
                postcount={postcount}
            />
}